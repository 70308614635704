// number-format.pipe.ts

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'customNumberFormat'
})
export class CustomNumberFormatPipe implements PipeTransform {
  transform(value: number | string): string {
    const numericValue = typeof value === 'string' ? +value : value;
    // Use the absolute value for formatting
    const absValue = Math.abs(numericValue);

    if (absValue >= 100000) {
      const millionPart = Math.floor(absValue / 100000);
      const remainder = absValue % 100000;
      const decimalPart = Math.round(remainder / 10000);

      let result = millionPart.toString();

      if (decimalPart > 0) {
        result += '.' + decimalPart.toString().padStart(2, '0');
      } else {
        result += '.00';
      }

      result += 'L';

      // Add negative sign if the original value was negative
      return numericValue < 0 ? '-' + result : result;
    } else if (absValue >= 1000) {
      const thousandPart = Math.floor(absValue / 1000);
      const remainder = absValue % 1000;
      const decimalPart = Math.round(remainder / 1000);

      let result = thousandPart.toString();

      if (decimalPart > 0) {
        result += '.' + decimalPart.toString().padStart(2, '0');
      } else {
        result += '.00';
      }

      result += 'K';

      // Add negative sign if the original value was negative
      return numericValue < 0 ? '-' + result : result;
    } else {
      // Add negative sign if the original value was negative
      return numericValue < 0 ? '-' + absValue.toString() : absValue.toString();
    }
  }
}