import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'searchFilter',
  pure: true,
})
export class SearchFilterPipe implements PipeTransform {
  transform(items: any[], searchText: string): any[] {
    if (!searchText) return items;

    const searchRegex = new RegExp(searchText, 'i');

    return items.filter((item) => {
      return (
        searchRegex.test(item.companyName) ||
        searchRegex.test(item.titles) ||
        searchRegex.test(item.bidderName) ||
        searchRegex.test(item.title) ||
        searchRegex.test(item.name) ||
        searchRegex.test(item.jobTitle) ||
        searchRegex.test(item.sellerCompanyName) ||
        searchRegex.test(item.buyerCompanyName)
      );
    });
  }
}
