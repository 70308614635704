import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { MessageService } from 'primeng/api';
import { Logger } from './logger.service';
import { ErrorFields, ReconsydeError, ValidationError } from './interfaces/error.interface';
import { environment } from 'src/app/environments/environment';

const log = new Logger('ErrorHandlerInterceptor');

/**
 * Adds a default error handler to all requests.
 */
@Injectable({
  providedIn: 'root',
})
export class ErrorHandlerInterceptor implements HttpInterceptor {
  constructor(private messageService: MessageService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(catchError((error) => this.errorHandler(error)));
  }

  // Customize the default error handler here if needed
  private errorHandler(response: HttpErrorResponse): Observable<HttpEvent<any>> {
    // console.log('response', response);
    this.beforeErrorToast(response.error);

    if (!environment.production) {
      // Do something with the error
      // log.error('Request error', response);
      // console.log('Request error', response);
    }
    throw response;
  }

  private beforeErrorToast(error: ReconsydeError | ValidationError) {
    console.log('Request error', error);

    if ((error as ReconsydeError)?.message)
      return this.showToast({
        param: (error as ReconsydeError).message,
        msg: `ERROR CODE: ${(error as ReconsydeError).errorCode || 500} `,
      });

    if ((error as ValidationError)?.errors)
      return (error as ValidationError).errors.forEach((error: any) => this.showToast(error));
  }

  private showToast(error: ErrorFields) {
    this.messageService.add({
      severity: 'error',
      summary: error.msg,
      detail: error.param,
    });
  }
}
