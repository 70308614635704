export function setPadding(
  multi: any,
  selectedDashboardCategory: any,
  screenWidth: number,
  screenHeight: number
) {
  let length = 0;
  if (multi?.length === 1) {
    length = 10000;
  } else if (multi?.length === 2) {
    length = 720;
  } else if (multi?.length === 3) {
    length = 330;
  } else if (multi?.length === 4) {
    length = 200;
  } else if (multi?.length === 5) {
    length = 150;
  }

  if (
    selectedDashboardCategory.key === 'revenue_vs_cost' ||
    selectedDashboardCategory.key === 'vendor'
  ) {
    if (multi.length === 1) {
      length = 10000;
    } else {
      length = 0;
    }
  }

  if (screenWidth <= 1366 && screenHeight <= 1024) {
    if (multi.length === 1) {
      length = 10000;
    } else if (multi?.length === 2) {
      length = 600;
    } else if (multi?.length === 3) {
      length = 250;
    } else if (multi?.length === 4) {
      length = 150;
    } else if (multi?.length === 5) {
      length = 100;
    }
  }

  if (screenWidth <= 983 && screenHeight <= 1311) {
    if (multi?.length === 1) {
      length = 10000;
    } else if (multi?.length === 2) {
      length = 500;
    } else if (multi?.length === 3) {
      length = 250;
    } else if (multi?.length === 4) {
      length = 150;
    } else if (multi?.length === 5) {
      length = 100;
    }
  }

  return length;
}

export function setDynamicFilter(selectedDashboardCategory: any) {
  let categories: any[] = [];
  switch (selectedDashboardCategory.key) {
    case 'revenue_vs_cost':
      categories = [
        { name: 'Business Head', key: 'Business_Head' },
        { name: 'Project', key: 'Project' },
        { name: 'Project Head', key: 'Project_Head' },
        { name: 'Business Bucket', key: 'Business_Bucket' },
        { name: 'Service Model', key: 'Service_Model' },
      ];
      break;
    case 'recoengine_bls_revenue':
      categories = [
        { name: 'Business Head', key: 'Business_Head' },
        { name: 'Project', key: 'Project' },
        { name: 'Project Head', key: 'Project_Head' },
        { name: 'Business Bucket', key: 'Business_Bucket' },
        { name: 'Service Name', key: 'Service_Name' },
        { name: 'Nature of Transaction', key: 'Nature_of_transaction' },
        { name: 'Service Model', key: 'Service_Model' },
        { name: 'Entity Name', key: 'Entity_Name' },
        { name: 'Location', key: 'Location' },
        { name: 'Vendor Name', key: 'Vendor_Name' },
      ];
      break;
    case 'recoengine_bls_cost':
      categories = [
        { name: 'Business Head', key: 'Business_Head' },
        { name: 'Project', key: 'Project' },
        { name: 'Project Head', key: 'Project_Head' },
        { name: 'Business Bucket', key: 'Business_Bucket' },
        { name: 'Service Model', key: 'Service_Model' },
        { name: 'Accounting Head', key: 'Accounting_Head' },
      ];
      break;
    case 'recoengine_bls_projection':
      categories = [
        { name: 'Business Head', key: 'Business_Head' },
        { name: 'Project', key: 'Project' },
        { name: 'Project Head', key: 'Project_Head' },
        { name: 'Business Bucket', key: 'Business_Bucket' },
        { name: 'Service Name', key: 'Service_Name' },
        { name: 'Transaction Frequency', key: 'Transaction_Frequency' },
        { name: 'Service Model', key: 'Service_Model' },
        { name: 'Vendor Name', key: 'Vendor_Name' },
      ];
      break;
    case 'recoengine_bls_budgets':
      categories = [
        { name: 'Business Head', key: 'Business_Head' },
        { name: 'Project', key: 'Project' },
        { name: 'Project Head', key: 'Project_Head' },
        { name: 'Business Bucket', key: 'Business_Bucket' },
        { name: 'Service Model', key: 'Service_Model' },
        { name: 'Accounting Head', key: 'Accounting_Head' },
      ];
      break;
    case 'recoengine_bls_revenue_target':
      categories = [
        { name: 'Business Head', key: 'Business_Head' },
        { name: 'Project', key: 'Project' },
        { name: 'Project Head', key: 'Project_Head' },
        { name: 'Business Bucket', key: 'Business_Bucket' },
        { name: 'Service Name', key: 'Service_Name' },
        { name: 'Transaction Frequency', key: 'Transaction_Frequency' },
        { name: 'Service Model', key: 'Service_Model' },
        { name: 'Vendor Name', key: 'Vendor_Name' },
      ];
      break;
    case 'vendor':
      categories = [
        { name: 'Business Head', key: 'Business_Head' },
        { name: 'Project', key: 'Project' },
        { name: 'Project Head', key: 'Project_Head' },
        { name: 'Business Bucket', key: 'Business_Bucket' },
        { name: 'Service Name', key: 'Service_Name' },
        { name: 'Service Model', key: 'Service_Model' },
        { name: 'Vendor Name', key: 'Vendor_Name' },
      ];
      break;
    default:
      categories = [
        { name: 'Business Head', key: 'Business_Head' },
        { name: 'Project Head', key: 'Project_Head' },
        { name: 'Business Bucket', key: 'Business_Bucket' },
        { name: 'Service Name', key: 'Service_Name' },
        { name: 'Nature of Transaction', key: 'Nature_of_transaction' },
        { name: 'Service Model', key: 'Service_Model' },
        { name: 'Entity Name', key: 'Entity_Name' },
        { name: 'Location', key: 'Location' },
        { name: 'Vendor Name', key: 'Vendor_Name' },
      ];
      break;
  }

  return categories;
}

export function getTop3Bottom3Value(
  multi: any,
  selectedCategory: any,
  selectedDashboardCategory: any,
  pieChartData: any
) {
  let firstLargest = { name: '', value: Number.MIN_SAFE_INTEGER };
  let secondLargest = { name: '', value: Number.MIN_SAFE_INTEGER };
  let thirdLargest = { name: '', value: Number.MIN_SAFE_INTEGER };
  let threeLargestNumber;

  if (multi.length > 0 && selectedCategory.key !== 'Location') {
    for (const obj of multi) {
      let graphObject = obj['series'][0];
      const graphName = obj.name;
      if (selectedDashboardCategory.key === 'revenue_vs_cost') {
        const sortedArray = multi
          .slice()
          .sort((a: any, b: any) => b['profit_loss%'] - a['profit_loss%']);
        threeLargestNumber = sortedArray.slice(0, 3);
        return threeLargestNumber;
      }

      if (selectedDashboardCategory.key === 'vendor') {
        const sortedArray = multi
          .slice()
          .sort((a: any, b: any) => b['achievement%'] - a['achievement%']);
        threeLargestNumber = sortedArray.slice(0, 3);
        return threeLargestNumber;
      }

      if (
        selectedDashboardCategory.key !== 'revenue_vs_cost' ||
        selectedDashboardCategory.key !== 'vendor'
      ) {
        if (graphObject.value > firstLargest.value) {
          thirdLargest.value = secondLargest.value;
          thirdLargest.name = secondLargest.name;
          secondLargest.value = firstLargest.value;
          secondLargest.name = firstLargest.name;
          firstLargest.value = graphObject.value;
          firstLargest.name = graphName;
        } else if (graphObject.value > secondLargest.value) {
          thirdLargest.value = secondLargest.value;
          thirdLargest.name = secondLargest.name;
          secondLargest.value = graphObject.value;
          secondLargest.name = graphName;
        } else if (graphObject.value > thirdLargest.value) {
          thirdLargest.value = graphObject.value;
          thirdLargest.name = graphName;
        }
      }
    }
  }

  if (selectedCategory.key === 'Location') {
    for (const obj of pieChartData) {
      let graphObject = obj;
      const graphName = obj.name;
      let threeLargestNumber = [];

      if (graphObject.value > firstLargest.value) {
        thirdLargest.value = secondLargest.value;
        thirdLargest.name = secondLargest.name;
        secondLargest.value = firstLargest.value;
        secondLargest.name = firstLargest.name;
        firstLargest.value = graphObject.value;
        firstLargest.name = graphName;
      } else if (graphObject.value > secondLargest.value) {
        thirdLargest.value = secondLargest.value;
        thirdLargest.name = secondLargest.name;
        secondLargest.value = graphObject.value;
        secondLargest.name = graphName;
      } else if (graphObject.value > thirdLargest.value) {
        thirdLargest.value = graphObject.value;
        thirdLargest.name = graphName;
      }
    }
  } else {
    const sortedArray = multi
      .slice()
      .sort((a: any, b: any) => b.value - a.value);
    threeLargestNumber = sortedArray.slice(0, 3);
    return threeLargestNumber;
  }

  return [firstLargest, secondLargest, thirdLargest];
}

export function getBottom3(
  multi: any,
  selectedDashboardCategory: any,
  single: any
) {
  let threeSmallestNumbers;
  if (multi.length > 0) {
    if (selectedDashboardCategory.key === 'revenue_vs_cost') {
      const sortedArray = multi
        .slice()
        .sort((a: any, b: any) => a['profit_loss%'] - b['profit_loss%']);
      threeSmallestNumbers = sortedArray.slice(0, 3);
    } else if (selectedDashboardCategory.key === 'vendor') {
      const sortedArray = multi
        .slice()
        .sort((a: any, b: any) => a['achievement%'] - b['achievement%']);
      threeSmallestNumbers = sortedArray.slice(0, 3);
    } else {
      const sortedArray = multi
        .slice()
        .sort((a: any, b: any) => a.value - b.value);
      threeSmallestNumbers = sortedArray.slice(0, 3);
    }
  }

  if (multi.length === 0 && single) {
    const sortedArray = single
      .slice()
      .sort((a: any, b: any) => a.value - b.value);
    threeSmallestNumbers = sortedArray.slice(0, 3);
  }

  return threeSmallestNumbers;
}

export function getTop3(
  multi: any,
  selectedDashboardCategory: any,
  single: any
) {
  let threeLargestNumber;
  multi;

  if (multi.length > 0) {
    if (selectedDashboardCategory.key === 'revenue_vs_cost') {
      const sortedArray = multi
        .slice()
        .sort((a: any, b: any) => b['profit_loss%'] - a['profit_loss%']);
      threeLargestNumber = sortedArray.slice(0, 3);
    } else if (selectedDashboardCategory.key === 'vendor') {
      const sortedArray = multi
        .slice()
        .sort((a: any, b: any) => b['achievement%'] - a['achievement%']);
      threeLargestNumber = sortedArray.slice(0, 3);
    } else {
      const sortedArray = multi
        .slice()
        .sort((a: any, b: any) => b.value - a.value);
      threeLargestNumber = sortedArray.slice(0, 3);
    }
    return threeLargestNumber;
  }

  if (multi.length === 0 && single) {
    const sortedArray = single
      .slice()
      .sort((a: any, b: any) => b.value - a.value);
    threeLargestNumber = sortedArray.slice(0, 3);
  }

  return threeLargestNumber;
}

export function getTotal(multi: any, single: any) {
  let total = 0;
  if (multi) {
    multi.forEach((graphObject: any) => {
      if (graphObject.series && graphObject.series.length > 0) {
        total += graphObject['net profit']
          ? graphObject['net profit']
          : graphObject.value;
      }
    });
  }

  if (single.length > 0 && multi.length === 0) {
    single.forEach((graphObject: any) => {
      total += graphObject.value;
    });
  }
  let res = total.toFixed(2);
  return Number(res);
}

export function setGroupPadding(multi: any) {
  let width = 0;
  if (multi?.length === 1) {
    width = 1460;
  } else if (multi?.length === 2) {
    width = 150;
  } else if (multi?.length === 3) {
    width = 60;
  } else {
    width = 20;
  }

  return width;
}
