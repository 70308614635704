<div class="pdf-container" id="contentToConvert">
  <div class="page-header">
    <div class="head-logo">
      <img src="assets/images/logo-pdf.png" width="142" height="39" />
    </div>
    <div class="pdf-intro">
      <p class="m-0">
        Generated by : <strong>Pabitra Sen</strong> &nbsp; Generated on :
        <strong>{{ getDate().currentDate }} </strong>
      </p>
    </div>
    <div class="product-pdf">
      <div class="by-pro">Product by</div>
      <img src="assets/images/product-logo.svg" width="107" height="33" />
    </div>
  </div>

  <div class="top-header">
    <div class="top-heading">
      {{ selectedDashboardCategory.name }} Dashboard
    </div>
    <div class="pdf-gene-info">
      <small>Duration</small>
      <p>{{ getDate().fromDate }} - {{ getDate().toDate }}</p>
    </div>
  </div>

  <div
    class="chart-inner-box"
    *ngFor="let chart of allDashboardCharts; let i = index"
  >
    <div class="header-space"></div>
    <div class="chartbox2">
      <div class="chart-heading">
        {{ getChartName(chart) + " (" + displayCashOrAccrual + ")" }}
      </div>
      <div
        class="chart-holder-box"
        #containerRef
        *ngIf="
          chart[i]?.category !== 'Location' &&
          getTotalAmount(chart, []) !== 0 &&
          !(
            selectedDashboardCategory.key === 'revenue_vs_cost' ||
            selectedDashboardCategory.key === 'vendor'
          )
        "
      >
        <ngx-charts-bar-vertical
          [view]="[700, containerRef.offsetHeight]"
          [scheme]="colorScheme"
          [results]="chart"
          [xAxis]="true"
          [yAxis]="true"
          [legend]="false"
          [showXAxisLabel]="true"
          [showYAxisLabel]="true"
          [xAxisLabel]="getChartName(chart)"
          [yAxisLabel]="'Revenue'"
          [animations]="true"
          [barPadding]="setBarPadding(chart)"
          [roundDomains]="true"
          class="gap-4"
        >
        </ngx-charts-bar-vertical>
      </div>

      <div
        class="chart-holder-box"
        #containerref1
        *ngIf="
          selectedDashboardCategory.key === 'revenue_vs_cost' ||
          selectedDashboardCategory.key === 'vendor'
        "
      >
        <ngx-charts-bar-vertical-2d
          [view]="[700, containerref1.offsetHeight]"
          [scheme]="colorScheme"
          [results]="chart"
          [gradient]="false"
          [xAxis]="true"
          [yAxis]="true"
          [animations]="false"
          [showXAxisLabel]="true"
          [showYAxisLabel]="true"
          [xAxisLabel]="xAxisLabel"
          [yAxisLabel]="'Revenue'"
          [roundDomains]="true"
          [legend]="true"
          [groupPadding]="setGroupBarPadding(chart)"
        >
        </ngx-charts-bar-vertical-2d>
      </div>
      <div
        class="summary-hor-box flex flex-row"
        *ngIf="getTotalAmount(chart, []) !== 0"
      >
        <div class="summary-total flex items-start justify-center flex-col">
          <p class="mb-3">Total</p>
          <div class="summary-value flex flex-col mr-6">
            <span class="rsym text-[#101828]">
              ₹ {{ getTotalAmount(chart, []).toFixed(2) | customNumberFormat}}</span
            >
          </div>
        </div>
        <div class="flex flex-col">
          <p class="total-style mt-4 mb-2">
            {{
              selectedDashboardCategory.key === "revenue_vs_cost"
                ? "Profitability"
                : selectedDashboardCategory.key === "vendor"
                ? "Projections"
                : ""
            }}
          </p>
          <div class="summar-top-btm-sec flex flex-row">
            <div class="summary-left flex flex-col">
              <p class="mb-3">Top 3</p>
              <div class="flex flex-row">
                <div
                  class="summary-value flex flex-col mr-2 text-left"
                  *ngFor="let top3 of getTop3Values(chart, [])"
                >
                  <span
                    class="text-[#475467] f-14 font-semibold mb-2 overflow-hidden h-[30px]"
                    >{{ top3.name }}</span
                  >
                  <span
                    class="rsym font-semibold text-[#101828] flex flex-col items-start gap-1"
                  >
                    ₹
                    {{
                      selectedDashboardCategory.key === "revenue_vs_cost" ||
                      selectedDashboardCategory.key === "vendor"
                        ? (top3["net profit"].toFixed(2) | customNumberFormat)
                        : (top3.value | customNumberFormat)
                    }}
                    

                    <div
                    *ngIf="
                      selectedDashboardCategory.key === 'revenue_vs_cost'
                    "
                  >
                    <span
                      class="p-badge p-component ng-star-inserted mt-2"
                      [ngClass]="
                        top3['achievement%'] < 100
                          ? 'p-badge-danger'
                          : 'p-badge-success'
                      "
                      >{{
                        selectedDashboardCategory.key === "vendor"
                          ? top3["achievement%"]
                          : selectedDashboardCategory.key ===
                            "revenue_vs_cost"
                          ? top3["profit_loss%"]
                          : ""
                      }}
                    </span>
                  </div> 



                    <div
                      *ngIf="
                        selectedDashboardCategory.key === 'vendor'
                      "
                    >
                      <span
                        class="p-component ng-star-inserted mt-5"
                        [ngClass]="
                          top3['achievement%'] < 100
                            ? 'bg-red-100 px-3 text-red-500 rounded-lg'
                            : 'bg-green-100 px-3 text-green-500 rounded-lg'
                        "
                        >{{
                          selectedDashboardCategory.key === "vendor"
                            ? top3["achievement%"]
                            : selectedDashboardCategory.key ===
                              "revenue_vs_cost"
                            ? top3["profit_loss%"]
                            : ""
                        }}
                      </span>
                    </div>
                  </span>
                </div>
              </div>
            </div>
            <div class="summary-right flex flex-col pl-6 bdr-r">
              <p class="mb-3">Bottom 3</p>
              <div class="flex flex-row">
                <div
                  class="summary-value flex flex-col mr-2 text-left"
                  *ngFor="let bottom3 of getBottom3Values(chart, [])"
                >
                  <span
                    class="text-[#475467] f-14 font-semibold mb-2 overflow-hidden h-[30px]"
                    >{{ bottom3.name }}</span
                  >
                  <span
                    class="rsym font-semibold text-[#101828] flex flex-col gap-1 items-start"
                  >
                    ₹
                    {{
                      selectedDashboardCategory.key === "revenue_vs_cost" ||
                      selectedDashboardCategory.key === "vendor"
                        ? (bottom3["net profit"].toFixed(2) | customNumberFormat)
                        : (bottom3.value.toFixed(2) | customNumberFormat)
                    }}
                    <div
                      *ngIf="
                        selectedDashboardCategory.key === 'revenue_vs_cost'
                      "
                    >
                      <span
                        class="p-badge p-component ng-star-inserted mt-2"
                        [ngClass]="
                          bottom3['achievement%'] < 100
                            ? 'p-badge-danger'
                            : 'p-badge-success'
                        "
                        >{{
                          selectedDashboardCategory.key === "vendor"
                            ? bottom3["achievement%"]
                            : selectedDashboardCategory.key ===
                              "revenue_vs_cost"
                            ? bottom3["profit_loss%"]
                            : ""
                        }}</span
                      >
                    </div>

                    <div
                    *ngIf="
                      selectedDashboardCategory.key === 'vendor'
                    "
                  >
                    <span
                      class=" p-component ng-star-inserted mt-2"
                      [ngClass]="
                        bottom3['achievement%'] < 100
                        ? 'bg-red-100 px-3 text-red-500 rounded-lg'
                            : 'bg-green-100 px-3 text-green-500 rounded-lg'
                      "
                      >{{
                        selectedDashboardCategory.key === "vendor"
                          ? bottom3["achievement%"]
                          : selectedDashboardCategory.key ===
                            "revenue_vs_cost"
                          ? bottom3["profit_loss%"]
                          : ""
                      }}</span
                    >
                  </div>


                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="chart-inner-box">
    <div class="header-space"></div>
    <div class="chartbox2">
      <div class="chart-heading">
        {{
          single && single[0] && single[0].category + " (" + cashOrAccrual + ")"
        }}
      </div>
      <div
        class="flex flex-row"
        #containerRef
        *ngIf="single && single[0] && single[0].category === 'Location'"
      >
        <div class="pie-chart mt-[-8rem]">
          <ngx-charts-pie-chart
            [view]="[500, 500]"
            [scheme]="pieChartColorScheme"
            [results]="single"
            [gradient]="false"
            [labels]="true"
            [animations]="false"
            [legend]="true"
            [doughnut]="true"
            [arcWidth]="0.5"
          >
          </ngx-charts-pie-chart>
        </div>
        <div class="summary-hor-box flex flex-col s-w350">
          <div class="summary-total flex items-start justify-center flex-col">
            <p class="mb-3">Total</p>
            <div class="summary-value flex flex-col mr-6">
              <span class="rsym text-[#101828]">
                ₹ {{ getTotalAmount([],single).toFixed(2) | customNumberFormat }}
              </span>
            </div>
          </div>
          <p class="total-style mt-4 mb-2">
            {{
              selectedDashboardCategory.key === "revenue_vs_cost"
                ? "Profitability"
                : selectedDashboardCategory.key === "vendor"
                ? "Projection"
                : ""
            }}
          </p>
          <div class="summary-right flex flex-col mb-3 bdr-t pt-3">
            <p class="mb-3">Top 3</p>
            <div class="flex flex-row pivalue">
              <div
                class="summary-value flex flex-col mr-6"
                *ngFor="let top3 of getTop3Values([], single)"
              >
                <span
                  class="text-[#475467] f-14 font-semibold mb-2 overflow-hidden h-[30px]"
                  >{{ top3.name }}</span
                >
                <span class="rsym font-semibold text-[#101828]">
                  ₹{{ top3.value.toFixed(2) | customNumberFormat }}</span
                >
              </div>
            </div>
          </div>
          <div class="summary-right flex flex-col bdr-t pt-3">
            <p class="mb-3">Bottom 3</p>
            <div class="flex flex-row pivalue">
              <div
                class="summary-value flex flex-col mr-6"
                *ngFor="let bottom3 of getBottom3Values([], single)"
              >
                <span
                  class="text-[#475467] f-14 font-semibold mb-2 overflow-hidden h-[30px]"
                  >{{ bottom3.name }}</span
                >
                <span class="rsym font-semibold text-[#101828]">
                  ₹ {{ bottom3.value.toFixed(2) | customNumberFormat }}</span
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="page-footer">
    <p class="m-0">© 2024 BancApp Automation Pvt Ltd. All Rights Reserved.</p>
  </div>
</div>
