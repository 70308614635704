import { Component, DoCheck, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit, DoCheck {
  isMenuOpened: boolean = false;
  url: any;
  hideHeader: boolean = false;

  constructor(private router: ActivatedRoute, private route: Router) {}
  ngDoCheck(): void {
    this.url = this.route.url;

    if (this.url.includes('export-pdf') || this.url.includes('data-insights')) {
      this.hideHeader = true;
    }
  }
  ngOnInit(): void {}

  toggleMenu(): void {
    this.isMenuOpened = !this.isMenuOpened;
  }
  clickedOutside(): void {
    this.isMenuOpened = false;
  }
}
