import { AfterViewInit, Component, HostListener, OnInit } from '@angular/core';
import { DashboardService } from '../dashboard.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import {
  getBottom3,
  getTop3,
  getTop3Bottom3Value,
  getTotal,
  setDynamicFilter,
  setGroupPadding,
  setPadding,
} from '../@shared/Common/function';
import { LegendPosition } from '@swimlane/ngx-charts';
import { ActivatedRoute, Router } from '@angular/router';
import { DatePipe } from '@angular/common';
import html2canvas from 'html2canvas';
import jspdf from 'jspdf';

@UntilDestroy()
@Component({
  selector: 'app-export-pdf',
  templateUrl: './export-pdf.component.html',
  styleUrls: ['./export-pdf.component.scss'],
  providers: [DatePipe],
})
export class ExportPdfComponent implements OnInit, AfterViewInit {
  public legendPosition: LegendPosition = LegendPosition.Below;
  errMsg: string = '';
  multi: any[] = [];
  xAxisLabel: any;
  colorScheme: any = {
    domain: ['#6941C6'],
  };
  padding: number = 0;
  categories: any[] = [];
  selectedDashboardCategory: any;
  cashOrAccrual: any;
  rangeDates: any;
  single: any[] = [];
  allDashboardCharts: any;
  screenWidth: number = 0;
  screenHeight: number = 0;
  pieChartColorScheme: any = {
    domain: ['#6941C6', '#9E77ED', '#D6BBFB'],
  };
  top3Values: any;
  printProcessing: boolean = false;
  text: string = '';
  displayCashOrAccrual: string = '';
  constructor(
    private dashboardService: DashboardService,
    private router: ActivatedRoute,
    private datePipe: DatePipe,
    private route: Router
  ) {
    this.selectedDashboardCategory =
    this.router.snapshot.queryParams['dashboard'];
    this.cashOrAccrual = this.router.snapshot.queryParams['value'];
    this.displayCashOrAccrual = this.router.snapshot.queryParams['cashAccural'];
    this.rangeDates = this.router.snapshot.queryParams['date'];
    this.selectedDashboardCategory = JSON.parse(this.selectedDashboardCategory);

  }

  ngAfterViewInit(): void {}

  ngOnInit(): void {
    this.categories = setDynamicFilter(this.selectedDashboardCategory);


    this.printProcessing = true;
    this.text = 'Generating Pdf';
    if (
      this.selectedDashboardCategory.key === 'revenue_vs_cost' ||
      this.selectedDashboardCategory.key === 'vendor'
    ) {
      this.getVSGraphData();
    } else {
      this.getAllDashboardData();
    }
    setTimeout(() => {
      this.exportPdf();
      this.text = '';
      this.printProcessing = false;
    }, 1000);
  }

  getAllDashboardData() {
    let allDashboardData: any = [];

    this.categories.forEach((category) => {
      let requestPayload = {
        dimensions: category.key,
        value: this.cashOrAccrual,
        table_name: this.selectedDashboardCategory.key,
        from_date: this.getDate().fromDate,
        to_date: this.getDate().toDate,
      };

      this.dashboardService
        .getDashboardGraph(requestPayload)
        .pipe(untilDestroyed(this))
        .subscribe(
          (data) => {
            console.log(data)
            this.errMsg = '';
            if (category.key === 'Location') {
              data.data.forEach((graphData: any, index: number) => {
                graphData['name'] = graphData['dim'];
                graphData['index'] = index + 1;
                graphData['isPiechart'] = true;
                graphData.series.forEach((chartItem: any) => {
                  graphData['value'] = chartItem['value'];
                });
                delete graphData['series'];
                delete graphData['dim'];
                graphData['category'] = category.name;
              });
              let graphData = data.data;
              Object.assign(this, { graphData });
              this.single = graphData;
              this.xAxisLabel = category.name;
              getTop3Bottom3Value(
                [],
                category,
                this.selectedDashboardCategory,
                this.single
              );
            } else {
              data.data.forEach((graphData: any, index: number) => {
                graphData['name'] = graphData['dim'];
                graphData['value'] = graphData['series'][0].value;
                graphData['index'] = index + 1;
                graphData['isPiechart'] = false;
                graphData['category'] = category.name;
                delete graphData['dim'];
              });
              let graphData = data.data;
              allDashboardData.push(graphData);
              Object.assign(this, { graphData });
              this.multi = graphData;
              this.xAxisLabel = category.name;
            }

            this.allDashboardCharts = allDashboardData;
          },
          (error) => {
            this.errMsg = 'No Data Found';
          }
        );
    });
  }

  getDate() {
    let fromDate;
    let toDate;
    let currentDate;
    if (this.rangeDates) {
      fromDate = this.datePipe.transform(this.rangeDates[0], 'MMMM-yyyy');
      toDate = this.datePipe.transform(this.rangeDates[1], 'MMMM-yyyy');
      currentDate = this.datePipe.transform(
        new Date().toISOString(),
        'dd-MMM-yyyy'
      );
    }

    if (!this.rangeDates) {
      fromDate = 'September-2023';
      toDate = 'October-2023';

      this.rangeDates = [
        new Date('2023-08-31T18:30:00.000Z'),
        new Date('2023-09-30T18:30:00.000Z'),
      ];
    }
    return {
      fromDate: fromDate ? fromDate : 'September-2023',
      toDate: toDate ? toDate : 'October-2023',
      currentDate: currentDate,
    };
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any): void {
    this.getScreenSize();
  }

  getScreenSize(): void {
    this.screenWidth = window.innerWidth;

    this.screenHeight = window.innerHeight;
  }

  setBarPadding(chart: any) {
    let length = setPadding(
      chart,
      this.selectedDashboardCategory,
      this.screenWidth,
      this.screenHeight
    );

    return length;
  }

  getBottom3Values(barChart: any, pieChart: any) {
    if (barChart.length > 0) {
      return getBottom3(barChart, this.selectedDashboardCategory, []);
    } else {
      return getBottom3([], this.selectedDashboardCategory, pieChart);
    }
  }

  getTop3Values(barChart: any, pieChart: any) {
    if (barChart.length > 0) {
      return getTop3(barChart, this.selectedDashboardCategory, []);
    } else {
      return getTop3([], this.selectedDashboardCategory, pieChart);
    }
  }

  getTotalAmount(barChart: any, pieChart: any) {
    let res = getTotal(barChart, pieChart);


    return res;
  }

  exportPdf() {
    window.print();
  }

  @HostListener('window:afterprint')
  onAfterPrint(): void {
    // Code to be executed after the print operation is complete
    // this.route.navigate(['/dashboard']);
  }

  getChartName(chart: any) {
    return chart[0].category;
  }

  getVSGraphData() {
    let allDashboardData: any = [];
    this.allDashboardCharts = [];

    this.categories.forEach((category) => {
      let newPayload;

      // set payload for revenue vs cost calculation
      if (this.selectedDashboardCategory.key === 'revenue_vs_cost') {
        newPayload = {
          dimensions: category.key,

          value: {
            recoengine_bls_revenue: 'Profit',

            recoengine_bls_cost: 'Amount',
          },

          table_name: this.setTableName(),

          from_date: this.getDate().fromDate,

          to_date: this.getDate().toDate,
        };
      }

      // set payload for revenue vs projection calculation
      if (this.selectedDashboardCategory.key === 'vendor') {
        newPayload = {
          dimensions: category.key,
          value: {
            recoengine_bls_revenue: 'Profit',
            recoengine_bls_projection: 'Net_Commission',
          },
          from_date: this.getDate().fromDate,

          to_date: this.getDate().toDate,
        };
      }

      this.dashboardService
        .getRevenueVsCostGraph(newPayload)
        .pipe(untilDestroyed(this))
        .subscribe(
          (data) => {
            this.errMsg = '';

            data.data.forEach((graphData: any, index: number) => {
              graphData['name'] = graphData['dim'];
              graphData['category'] = category.name;

              graphData['series'] = [
                {
                  name: 'Revenue',
                  value: graphData['revenue profit'],
                },
                {
                  name:
                    this.selectedDashboardCategory.key === 'vendor'
                      ? 'Projection'
                      : 'Cost',
                  value: graphData['cost amount'],
                },
              ];

              graphData['index'] = index + 1;
              delete graphData['dim'];
            });
            let groupedGraphData = data.data;

            allDashboardData.push(groupedGraphData);
            this.allDashboardCharts = allDashboardData;
            this.colorScheme = {
              domain: ['#6941C6', '#9E77ED'],
            };
            Object.assign(this, { groupedGraphData });
            this.xAxisLabel = category.name;
          },
          (error) => {
            this.errMsg = 'No Data Found';
          }
        );
    });
  }

  setTableName() {
    let res;

    if (
      this.selectedDashboardCategory.key === 'revenue_vs_cost' ||
      this.selectedDashboardCategory.key === 'vendor'
    ) {
      res =
        this.selectedDashboardCategory.key === 'vendor'
          ? ['recoengine_bls_revenue', 'recoengine_bls_projection']
          : ['recoengine_bls_revenue', 'recoengine_bls_cost'];
    } else {
      res = this.selectedDashboardCategory.key;
    }

    return res;
  }

  setGroupBarPadding(multi: any) {
    return setGroupPadding(multi);
  }
}
