<!-- <div>
    <h1>
      {{name}}
    </h1>
    </div>
    <div> <button  (click)="generatePDF()">Export To PDF</button></div> -->
<div class="pdf-container">
    <header>
        <div class="head-logo">
            <img src="/src/assets/images/logo-pdf.png" width="142" height="39"
                border="0" />
        </div>
        <div class="pdf-intro">
            <p>
                Generated by : <strong>Pabitra Sen, <small>24 Jan 2023</small></strong>
            </p>
        </div>
        <div class="product-pdf">
            <div class="by-pro">Product by</div>
            <img src="/src/assets/images/product-logo.png" width="107" height="33"
                border="0" />
        </div>
    </header>
    <div class="top-header">
        <div class="top-heading">
            Revenue Dashboard
        </div>
        <div class="pdf-gene-info">
            <small>Duration</small>
            <p>12 Jan 23 - 18 Jan 23</p>
        </div>
    </div>
    <div class="chart-inner-box">
        <div class="chart-heading">Business head</div>
        <!---place here chart-->

    </div>
    <div class="chart-inner-box">
        <div class="chart-heading">Business head</div>
        <!---place here chart-->

    </div>
    <footer>
        <p>© 2023 BancApp Automation Pvt Ltd. All Rights Reserved.</p>
        <span>1 of 2</span>
    </footer>
</div>