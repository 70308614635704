import { Component, DoCheck, HostListener, OnInit } from '@angular/core';
import { DashboardService } from './dashboard.service';
import { MenuItem } from 'primeng/api';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { SearchFilterPipe } from './@shared/pipes/search-filter.pipe';
import html2canvas from 'html2canvas';
import jspdf from 'jspdf';
import * as FileSaver from 'file-saver';
import { LegendPosition } from '@swimlane/ngx-charts';
import * as XLSX from 'xlsx';
import { DatePipe } from '@angular/common';
import { setDynamicFilter, setPadding } from './@shared/Common/function';
import { Router } from '@angular/router';

@UntilDestroy()
@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
  providers: [SearchFilterPipe, DatePipe],
})
export class DashboardComponent implements OnInit {
  public legendPosition: LegendPosition = LegendPosition.Below;
  multi!: any[];
  view: any = [200, 400];
  viewLineChart: any = [600, 400];
  viewPieChart: any = [600, 400];
  rangeDates!: Date[];
  // options
  showXAxis: boolean = true;
  showYAxis: boolean = true;
  gradient: boolean = false;
  showLegend: boolean = false;
  showXAxisLabel: boolean = true;
  xAxisLabel: string = '';
  showYAxisLabel: boolean = true;
  yAxisLabel: string = 'Revenue';
  animations: boolean = true;
  visible: boolean = false;
  visibleDashboard: boolean = false;
  visiblePopup: boolean = false;

  colorScheme: any = {
    domain: ['#6941C6'],
  };

  stateOptions: any[] = [
    { label: 'Accrual', value: 'Accrual' },
    { label: 'Cash', value: 'Cash' },
  ];

  reportStateOptions: any[] = [
    { label: 'View all', value: 'View_All' },
    { label: 'Top 3', value: 'top' },
    { label: 'Bottom 3', value: 'bottom' },
  ];

  value: string = this.stateOptions[0].value;

  selectedFilterCategory: any = {
    name: '',
    key: '',
  };

  categories: any[] = [
    { name: 'Business Head', key: 'Business_Head' },
    { name: 'Project', key: 'Project' },
    { name: 'Project Head', key: 'Project_Head' },
    { name: 'Business Bucket', key: 'Business_Bucket' },
    { name: 'Service Name', key: 'Service_Name' },
    { name: 'Nature of Transaction', key: 'Nature_of_transaction' },
    { name: 'Service Model', key: 'Service_Model' },
    { name: 'Entity Name', key: 'Entity_Name' },
    { name: 'Location', key: 'Location' },
    { name: 'Vendor Name', key: 'Vendor_Name' },
  ];

  dashboardCategories: any[] = [
    { name: 'Revenue', key: 'recoengine_bls_revenue' },
    { name: 'Cost', key: 'recoengine_bls_cost' },
    { name: 'Agreements', key: 'businessBucket' },
    { name: 'Projections', key: 'recoengine_bls_projection' },
    { name: 'Budgets', key: 'recoengine_bls_budgets' },
    { name: 'Future Projects', key: 'serviceModel' },
    { name: 'Revenue Targets', key: 'recoengine_bls_revenue_target' },
    { name: 'Revenue Vs Costs', key: 'revenue_vs_cost' },
    { name: 'Revenue Vs Projections', key: 'vendor' },
  ];

  selectedCategory: any = this.categories[0];

  selectedDashboardCategory: any = this.dashboardCategories[0];

  data = [
    { name: '> 95', value: 765 },
    { name: '90 - 94', value: 123 },
    { name: '< 90', value: 84 },
  ];
  single: any[] = [];
  lineChart: any[] = [];

  enableChart = {
    barChart: false,
    pieChart: false,
    lineChart: false,
    groupedBarChart: false,
  };
  items: MenuItem[] | undefined;
  feildData: any;
  displayBackButton: boolean = false;
  displayFilterCategory: any;
  searchText: string = '';
  reportOption: string = this.reportStateOptions[0].value;
  products: any[] = [];
  errMsg: string = '';
  filteredCategory: any;
  tempBarData: any;
  activeIndex = 0;
  maxNumber: number = Number.MAX_SAFE_INTEGER;
  largest: number = 0;
  graphData: any[] = [];
  barPadding: number = 0;
  legendData = ['Revenue', 'Cost'];
  reversedArray: any[] = [];
  screenWidth: number = 0;
  screenHeight: number = 0;
  changedView: string = '';
  changedDrillView: string = '';
  selectedDate: any;
  constructor(
    private dashboardService: DashboardService,
    private searchFilter: SearchFilterPipe,
    private datePipe: DatePipe,
    private router: Router
  ) {
    this.getScreenSize();
  }

  ngOnInit(): void {
    this.getDate();
    this.enableChart.barChart = true;

    let requestPayload = {
      dimensions: this.selectedCategory.key,
      value: this.value,
      table_name: this.setTableName(),
      from_date: this.selectedDate.fromDate,
      to_date: this.selectedDate.toDate,
    };

    this.dashboardService
      .getDashboardGraph(requestPayload)
      .pipe(untilDestroyed(this))
      .subscribe(
        (data) => {
          this.errMsg = '';
          data.data.forEach((graphData: any, index: number) => {
            graphData['name'] = graphData['dim'];
            graphData['value'] = graphData['series'][0].value;
            graphData['index'] = index + 1;

            delete graphData['dim'];
          });
          let graphData = data.data;
          this.products = graphData;

          Object.assign(this, { graphData });
          this.multi = graphData;
          this.xAxisLabel = this.selectedCategory.name;
        },
        (error) => {
          this.errMsg = 'No Data Found';
        }
      );
  }

  onSelect(event: any) {
    if (!this.displayBackButton) {
      this.visiblePopup = true;
      this.feildData = event?.name;

      if (
        this.selectedDashboardCategory.key === 'vendor' ||
        this.selectedDashboardCategory.key === 'revenue_vs_cost'
      ) {
        this.feildData = event.series;
      }
    } else {
      this.feildData = '';
    }
  }

  showDialog() {
    this.visible = true;
  }

  onActivate(data: any): void {}

  onDeactivate(data: any): void {}

  selectCategory(category: any) {
    this.changedView = category.key;
    this.reportOption = this.reportStateOptions[0].value;
    setTimeout(() => {
      this.visible = false;
      this.visibleDashboard = false;
      this.displayBackButton = false;
    }, 300);
    this.onFilterChange();
  }

  selectCategoryFilter(category: any) {
    this.filteredCategory = category;

    this.displayBackButton = true;
    this.xAxisLabel = category?.name;

    if (
      this.selectedDashboardCategory.key === 'revenue_vs_cost' ||
      this.selectedDashboardCategory.key === 'vendor'
    ) {
      this.setTableName();
      this.enableChart.groupedBarChart = true;
      this.enableChart.barChart = false;
      let newPayload;
      if (this.selectedDashboardCategory.key === 'revenue_vs_cost') {
        newPayload = {
          dimensions:
            this.filteredCategory && this.filteredCategory.key
              ? this.filteredCategory.key
              : this.selectedCategory.key,

          value: {
            recoengine_bls_revenue: 'Profit',

            recoengine_bls_cost: 'Amount',
          },

          // table_name: this.setTableName(),

          // from_date: this.rangeDates[1]

          // to_date: 'October-2023',
          condition_column: this.selectedCategory.key,
          condition_value: this.feildData,
          from_date: this.selectedDate.fromDate,
          to_date: this.selectedDate.toDate,
        };

        if (this.changedView === '' && this.changedDrillView === '') {
          delete newPayload.condition_column;
          delete newPayload.condition_value;
        }
      }

      if (this.selectedDashboardCategory.key === 'vendor') {
        newPayload = {
          dimensions:
            this.filteredCategory && this.filteredCategory.key
              ? this.filteredCategory.key
              : this.selectedCategory.key,

          value: {
            recoengine_bls_revenue: 'Profit',

            recoengine_bls_projection: 'Net_Commission',
          },

          table_name: this.setTableName(),

          from_date: this.selectedDate.fromDate,

          to_date: this.selectedDate.toDate,

          condition_column: this.selectedCategory.key,
          condition_value: this.feildData,
        };
        if (this.changedView === '' && this.changedDrillView === '') {
          delete newPayload.condition_column;
          delete newPayload.condition_value;
        }
      }

      this.dashboardService
        .getRevenueVsCostGraph(newPayload)
        .pipe(untilDestroyed(this))
        .subscribe(
          (data) => {
            this.errMsg = '';
            this.visiblePopup = false;

            if (
              this.filteredCategory.key === 'Business_Head' ||
              this.filteredCategory.key === 'Project_Head' ||
              this.filteredCategory.key === 'Business_Head' ||
              this.filteredCategory.key === 'Business_Bucket' ||
              this.filteredCategory.key === 'Service_Name' ||
              this.filteredCategory.key === 'Nature_of_transaction' ||
              this.filteredCategory.key === 'Service_Model' ||
              this.filteredCategory.key === 'Entity_Name' ||
              this.filteredCategory.key === 'Project' ||
              this.filteredCategory.key === 'Vendor_Name'
            ) {
              this.enableChart.barChart = false;
              this.getGroupedBarChartData(data.data);
            }

            if (this.filteredCategory.key === 'Location') {
              this.getPieChartData(data.data);
            }
          },
          (error) => {
            this.errMsg = 'No Data Found';
          }
        );
    } else {
      this.enableChart.groupedBarChart = true;
      let requestPayload = {
        dimensions: category.key ? category.key : this.selectedCategory.key,
        value:
          this.selectedDashboardCategory.key === 'recoengine_bls_cost' ||
          this.selectedDashboardCategory.key === 'recoengine_bls_budgets'
            ? 'Amount'
            : this.selectedDashboardCategory.key ===
                'recoengine_bls_projection' ||
              this.selectedDashboardCategory.key ===
                'recoengine_bls_revenue_target'
            ? 'Transaction_Value'
            : this.value,
        table_name: this.setTableName(),
        from_date: this.selectedDate.fromDate,
        to_date: this.selectedDate.toDate,
        condition_column: this.selectedCategory.key,
        condition_value: this.feildData,
      };

      if (this.changedView === '' && this.changedDrillView === '') {
        delete requestPayload.condition_column;
        delete requestPayload.condition_value;
      }

      setTimeout(() => {
        this.visiblePopup = false;
      }, 300);

      this.dashboardService
        .getDashboardGraph(requestPayload)
        .pipe(untilDestroyed(this))
        .subscribe(
          (data) => {
            this.errMsg = '';
            if (category.key === 'Location') {
              this.getPieChartData(data.data);
            } else {
              this.getBarChartData(data.data);
            }
            // this.enableChart.barChart = true;
          },
          (error) => {
            this.errMsg = 'No Data Found';
          }
        );
    }
  }

  filterCategory() {
    return this.categories.filter(
      (category) => category.key !== this.selectedCategory.key
    );
  }

  resetGraph() {
    this.selectedCategory = this.categories.find(
      (category) => category.key === this.selectedCategory.key
    );
    this.changedDrillView = '';
    this.changedView = '';

    this.selectCategoryFilter(this.selectedCategory);
    this.changedDrillView = this.selectedCategory.key;
    this.changedView = '';
    // this.selectedFilterCategory.key = ''; //temp
    this.displayBackButton = false;
    this.displayFilterCategory = '';
  }

  onCashAccrualChange() {
    if (this.changedDrillView === '' && this.changedView === '') {
      this.changedDrillView = this.selectedCategory.key;
    }
    let requestPayload = {
      dimensions:
        this.changedDrillView !== '' ? this.changedDrillView : this.changedView,
      value:
        this.selectedDashboardCategory.key === 'recoengine_bls_cost' ||
        this.selectedDashboardCategory.key === 'recoengine_bls_budgets'
          ? 'Amount'
          : this.selectedDashboardCategory.key ===
              'recoengine_bls_projection' ||
            this.selectedDashboardCategory.key ===
              'recoengine_bls_revenue_target'
          ? 'Transaction_Value'
          : this.value,
      table_name: this.setTableName(),
      from_date: this.selectedDate.fromDate,
      to_date: this.selectedDate.toDate,
      condition_column: this.selectedCategory.key,
      condition_value: this.feildData,
    };

    if (this.displayBackButton === false) {
      delete requestPayload.condition_column;
      delete requestPayload.condition_value;
    }

    if (this.feildData === '') {
      delete requestPayload.condition_value;
    }

    this.dashboardService
      .getDashboardGraph(requestPayload)
      .pipe(untilDestroyed(this))
      .subscribe(
        (data) => {
          this.errMsg = '';

          if (this.displayBackButton) {
            if (this.selectedFilterCategory.key !== 'Location') {
              this.getBarChartData(data.data);
            } else {
              this.getPieChartData(data.data);
            }
          } else {
            if (this.selectedCategory.key !== 'Location') {
              this.getBarChartData(data.data);
            } else {
              this.getPieChartData(data.data);
            }
          }
        },
        (error) => {
          this.errMsg = 'No Data Found';
        }
      );
  }

  onDashboardChange() {
    this.feildData = '';
    this.categories = setDynamicFilter(this.selectedDashboardCategory);
    this.selectedCategory = this.categories[0];
    this.xAxisLabel = this.selectedCategory.name;
    this.displayBackButton = false;
    this.enableChart = {
      barChart: true,
      pieChart: false,
      lineChart: false,
      groupedBarChart: false,
    };

    this.setDisabledStateOption();

    if (
      this.selectedDashboardCategory.key === 'revenue_vs_cost' ||
      this.selectedDashboardCategory.key === 'vendor'
    ) {
      this.setTableName();
      this.enableChart.groupedBarChart = true;
      this.enableChart.barChart = false;
      let newPayload;

      if (this.selectedDashboardCategory.key === 'revenue_vs_cost') {
        newPayload = {
          dimensions: this.selectedCategory.key,

          value: {
            recoengine_bls_revenue: 'Profit',

            recoengine_bls_cost: 'Amount',
          },
          from_date: this.selectedDate.fromDate,
          to_date: this.selectedDate.toDate,
        };
      }

      if (this.selectedDashboardCategory.key === 'vendor') {
        newPayload = {
          dimensions: this.selectedCategory.key,

          value: {
            recoengine_bls_revenue: 'Profit',

            recoengine_bls_projection: 'Net_Commission',
          },
          from_date: this.selectedDate.fromDate,
          to_date: this.selectedDate.toDate,
        };
      }

      this.dashboardService
        .getRevenueVsCostGraph(newPayload)
        .pipe(untilDestroyed(this))
        .subscribe(
          (data) => {
            this.errMsg = '';

            this.enableChart.barChart = false;
            this.getGroupedBarChartData(data.data);
          },
          (error) => {
            this.errMsg = 'No Data Found';
          }
        );
    } else {
      this.enableChart.groupedBarChart = false;
      let requestPayload = {
        dimensions: this.categories[0].key,
        value:
          this.selectedDashboardCategory.key === 'recoengine_bls_cost' ||
          this.selectedDashboardCategory.key === 'recoengine_bls_budgets'
            ? 'Amount'
            : this.selectedDashboardCategory.key ===
                'recoengine_bls_projection' ||
              this.selectedDashboardCategory.key ===
                'recoengine_bls_revenue_target'
            ? 'Transaction_Value'
            : this.value,
        table_name: this.setTableName(),
        from_date: this.selectedDate.fromDate,
        to_date: this.selectedDate.toDate,
      };

      this.dashboardService
        .getDashboardGraph(requestPayload)
        .pipe(untilDestroyed(this))
        .subscribe(
          (data) => {
            this.errMsg = '';
            if (this.selectedCategory.key !== 'Location') {
              this.getBarChartData(data.data);
            } else {
              this.getPieChartData(data.data);
            }
          },
          (error) => {
            this.errMsg = 'No Data Found';
          }
        );
    }
  }

  // view by filter change event handler
  onFilterChange() {
    this.xAxisLabel = this.selectedCategory.name;

    if (
      this.selectedDashboardCategory.key === 'revenue_vs_cost' ||
      this.selectedDashboardCategory.key === 'vendor'
    ) {
      this.enableChart.groupedBarChart = true;
      this.enableChart.barChart = false;
      let newPayload;

      // set payload for revenue vs cost calculation
      if (this.selectedDashboardCategory.key === 'revenue_vs_cost') {
        newPayload = {
          dimensions: this.selectedCategory.key,

          value: {
            recoengine_bls_revenue: 'Profit',

            recoengine_bls_cost: 'Amount',
          },

          table_name: this.setTableName(),

          from_date: this.selectedDate.fromDate,

          to_date: this.selectedDate.toDate,
        };
      }

      // set payload for revenue vs projection calculation
      if (this.selectedDashboardCategory.key === 'vendor') {
        newPayload = {
          dimensions: this.selectedCategory.key,
          value: {
            recoengine_bls_revenue: 'Profit',
            recoengine_bls_projection: 'Net_Commission',
          },
          // table_name: this.setTableName(),
          // from_date: this.getDate().fromDate,
          // to_date: this.rangeDates[1],

          from_date: this.selectedDate.fromDate,

          to_date: this.selectedDate.toDate,
        };
      }

      this.dashboardService
        .getRevenueVsCostGraph(newPayload)
        .pipe(untilDestroyed(this))
        .subscribe(
          (data) => {
            this.errMsg = '';
            if (this.selectedCategory.key === 'Location') {
              this.getPieChartData(data.data);
            } else {
              this.enableChart.barChart = false;
              this.getGroupedBarChartData(data.data);
            }
          },
          (error) => {
            this.errMsg = 'No Data Found';
          }
        );
    } else {
      this.enableChart.groupedBarChart = false;
      this.enableChart.barChart = true;

      let requestPayload = {
        dimensions: this.selectedCategory.key,
        value:
          this.selectedDashboardCategory.key === 'recoengine_bls_cost' ||
          this.selectedDashboardCategory.key === 'recoengine_bls_budgets'
            ? 'Amount'
            : this.selectedDashboardCategory.key ===
                'recoengine_bls_projection' ||
              this.selectedDashboardCategory.key ===
                'recoengine_bls_revenue_target'
            ? 'Transaction_Value'
            : this.value,
        table_name: this.setTableName(),
        from_date: this.selectedDate.fromDate,
        to_date: this.selectedDate.toDate,
      };

      this.dashboardService
        .getDashboardGraph(requestPayload)
        .pipe(untilDestroyed(this))
        .subscribe(
          (data) => {
            this.errMsg = '';
            if (data.data.length > 0) {
              if (this.selectedCategory.key !== 'Location') {
                this.getBarChartData(data.data);
              } else {
                this.getPieChartData(data.data);
              }
            } else {
              this.errMsg = 'No Data Found';
            }
          },
          (error) => {
            this.errMsg = 'No Data Found';
          }
        );
    }
  }

  setVisibility() {
    this.visible = true;
  }

  closePopup() {
    this.visible = false;
    this.visibleDashboard = false;
  }

  getLineChartData(data: any) {
    data.forEach((graphData: any, index: number) => {
      graphData['name'] = graphData['dim'];
      graphData['index'] = index + 1;
      delete graphData['dim'];
    });
    let graphData = data;
    this.products = graphData;

    Object.assign(this, { graphData });
    this.lineChart = graphData;

    this.xAxisLabel = this.selectedCategory.name;
  }

  getPieChartData(data: any) {
    this.multi = [];
    this.enableChart.barChart = false;
    this.enableChart.groupedBarChart = false;
    this.enableChart.pieChart = true;

    data.forEach((chartData: any, index: number) => {
      chartData['name'] = chartData['dim'];
      chartData['index'] = index + 1;
      chartData.series.forEach((chartItem: any) => {
        chartData['value'] = chartItem['value'];
      });
      delete chartData['series'];
      delete chartData['dim'];
    });
    let pieChartData = data;
    this.products = pieChartData;
    this.graphData = pieChartData;

    Object.assign(this, { pieChartData });
    this.single = pieChartData;

    this.getTotal(this.single);
    this.multi = [];
    this.getTop3Bottom3Value();
    this.getBottom3();
    this.colorScheme = {
      domain: ['#6941C6', '#9E77ED', '#D6BBFB'],
    };
  }

  getBarChartData(data: any) {
    this.enableChart.groupedBarChart = false;
    this.enableChart.pieChart = false;
    this.enableChart.barChart = true;
    data.forEach((graphData: any, index: number) => {
      graphData['name'] = graphData['dim'];
      graphData['value'] = graphData['series'][0].value;
      graphData['index'] = index + 1;
      delete graphData['dim'];
    });
    let graphData = data;
    this.products = graphData;
    this.colorScheme = {
      domain: ['#6941C6'],
    };
    Object.assign(this, { graphData });
    this.multi = graphData;

    this.tempBarData = graphData;
    this.getTotal(this.multi);
    this.getTop3Bottom3Value();
    this.getBottom3();
    // this.xAxisLabel = this.selectedCategory.name;
  }

  getGroupedBarChartData(data: any) {
    this.enableChart.barChart = false;
    this.enableChart.groupedBarChart = true;
    let tempArray: any[] = [
      {
        name: '',
        value: 0,
      },
      {
        name: '',
        value: 0,
      },
    ];

    data.forEach((graphData: any, index: number) => {
      graphData['name'] = graphData['dim'];

      graphData['series'] = [
        {
          name: 'Revenue',
          value: graphData['revenue profit'],
        },
        {
          name:
            this.selectedDashboardCategory.key === 'vendor'
              ? 'Projection'
              : 'Cost',
          value: graphData['cost amount'],
        },
      ];

      graphData['index'] = index + 1;

      delete graphData['dim'];
    });
    let graphData = data;

    this.products = graphData;

    Object.assign(this, { graphData });
    this.multi = graphData;
    this.tempBarData = graphData;
    this.colorScheme = {
      domain: ['#6941C6', '#9E77ED'],
    };

    this.getTotal(this.multi);
    this.getTop3Bottom3Value();
    this.getBottom3();
  }

  onSearchInputChange(event: Event) {
    this.searchText = (event.target as HTMLInputElement).value.trim();
  }

  getAllProducts() {
    let products = [];
    if (this.products.length > 0) {
      products = this.products;
    }
    return products;
  }

  getToggleName() {
    this.selectedDashboardCategory.key === 'recoengine_bls_cost' ||
    this.selectedDashboardCategory.key === 'recoengine_bls_budgets'
      ? 'Amount'
      : this.selectedDashboardCategory.key === 'recoengine_bls_projection' ||
        this.selectedDashboardCategory.key === 'recoengine_bls_revenue_target'
      ? 'Transaction_Value'
      : this.stateOptions.splice(0, 1);
  }

  setDisabledStateOption() {
    this.dashboardCategories.forEach((category) => {
      if (this.selectedDashboardCategory.name === category.name) {
        if (
          category.name === 'Cost' ||
          category.name === 'Budgets' ||
          category.name === 'Revenue Targets' ||
          category.name === 'Projections'
        ) {
          this.stateOptions[1].constant = false;
          this.stateOptions[0].constant = true;
          this.value = this.stateOptions[1].value;
        } else if (category.name === 'Revenue') {
          this.stateOptions[1].constant = false;
          this.stateOptions[0].constant = false;
          this.value = this.stateOptions[0].value;
        } else {
          this.stateOptions[1].constant = false;
          this.stateOptions[0].constant = true;
          this.value = this.stateOptions[0].value;
          this.value = this.stateOptions[1].value;
        }
      }
    });
  }

  getTop3Bottom3Value() {
    let firstLargest = { name: '', value: Number.MIN_SAFE_INTEGER };
    let secondLargest = { name: '', value: Number.MIN_SAFE_INTEGER };
    let thirdLargest = { name: '', value: Number.MIN_SAFE_INTEGER };
    let threeLargestNumber;

    if (this.multi.length > 0 && this.selectedCategory.key !== 'Location') {
      for (const obj of this.multi) {
        let graphObject = obj['series'][0];
        const graphName = obj.name;
        if (this.selectedDashboardCategory.key === 'revenue_vs_cost') {
          const sortedArray = this.multi
            .slice()
            .sort((a, b) => b['profit_loss%'] - a['profit_loss%']);
          threeLargestNumber = sortedArray.slice(0, 3);
          return threeLargestNumber;
        }

        if (this.selectedDashboardCategory.key === 'vendor') {
          const sortedArray = this.multi
            .slice()
            .sort((a, b) => b['achievement%'] - a['achievement%']);
          threeLargestNumber = sortedArray.slice(0, 3);
          return threeLargestNumber;
        }

        if (
          this.selectedDashboardCategory.key !== 'revenue_vs_cost' ||
          this.selectedDashboardCategory.key !== 'vendor'
        ) {
          if (graphObject.value > firstLargest.value) {
            thirdLargest.value = secondLargest.value;
            thirdLargest.name = secondLargest.name;
            secondLargest.value = firstLargest.value;
            secondLargest.name = firstLargest.name;
            firstLargest.value = graphObject.value;
            firstLargest.name = graphName;
          } else if (graphObject.value > secondLargest.value) {
            thirdLargest.value = secondLargest.value;
            thirdLargest.name = secondLargest.name;
            secondLargest.value = graphObject.value;
            secondLargest.name = graphName;
          } else if (graphObject.value > thirdLargest.value) {
            thirdLargest.value = graphObject.value;
            thirdLargest.name = graphName;
          }
        }
      }
    }

    if (
      (this.selectedCategory.key === 'Location' && !this.displayBackButton) ||
      (this.selectedFilterCategory?.key === 'Location' &&
        this.displayBackButton)
    ) {
      for (const obj of this.products) {
        let graphObject = obj;
        const graphName = obj.name;
        let threeLargestNumber = [];

        if (graphObject.value > firstLargest.value) {
          thirdLargest.value = secondLargest.value;
          thirdLargest.name = secondLargest.name;
          secondLargest.value = firstLargest.value;
          secondLargest.name = firstLargest.name;
          firstLargest.value = graphObject.value;
          firstLargest.name = graphName;
        } else if (graphObject.value > secondLargest.value) {
          thirdLargest.value = secondLargest.value;
          thirdLargest.name = secondLargest.name;
          secondLargest.value = graphObject.value;
          secondLargest.name = graphName;
        } else if (graphObject.value > thirdLargest.value) {
          thirdLargest.value = graphObject.value;
          thirdLargest.name = graphName;
        }
      }
    } else {
      const sortedArray = this.multi.slice().sort((a, b) => b.value - a.value);
      threeLargestNumber = sortedArray.slice(0, 3);
      return threeLargestNumber;
    }

    return [firstLargest, secondLargest, thirdLargest];
  }

  getBottom3() {
    let threeSmallestNumbers;
    if (this.multi?.length > 0) {
      if (this.selectedDashboardCategory.key === 'revenue_vs_cost') {
        const sortedArray = this.multi
          .slice()
          .sort((a, b) => a['profit_loss%'] - b['profit_loss%']);
        threeSmallestNumbers = sortedArray.slice(0, 3);
      } else if (this.selectedDashboardCategory.key === 'vendor') {
        const sortedArray = this.multi
          .slice()
          .sort((a, b) => a['achievement%'] - b['achievement%']);
        threeSmallestNumbers = sortedArray.slice(0, 3);
      } else {
        const sortedArray = this.multi
          .slice()
          .sort((a, b) => a['series'][0].value - b['series'][0].value);
        threeSmallestNumbers = sortedArray.slice(0, 3);
      }
    }

    if (this.multi.length === 0 && this.single) {
      const sortedArray = this.single.slice().sort((a, b) => a.value - b.value);
      threeSmallestNumbers = sortedArray.slice(0, 3);
    }

    return threeSmallestNumbers;
  }

  getTotal(multi: any) {
    let total = 0;
    if (multi) {
      multi.forEach((graphObject: any) => {
        if (graphObject.series && graphObject.series.length > 0) {
          total += graphObject['net profit']
            ? graphObject['net profit']
            : graphObject.series[0].value;
        }
      });
    }

    if (this.single.length > 0 && this.multi.length === 0) {
      this.single.forEach((graphObject) => {
        total += graphObject.value;
      });
    }
    let res = total.toFixed(2);
    return Number(res);
  }

  filterTableData() {
    let resultArray = [];
    if (this.reportOption === 'bottom') {
      if (
        this.selectedDashboardCategory.key !== 'revenue_vs_cost' ||
        this.selectedDashboardCategory.key !== 'vendor'
      ) {
        if (
          (this.selectedCategory.key === 'Location' &&
            !this.displayBackButton) ||
          (this.filteredCategory?.key === 'Location' && this.displayBackButton)
        ) {
          const sortedArray = this.products
            .slice()
            .sort((a, b) => a.value - b.value);
          resultArray = sortedArray.slice(0, 3);
        } else {
          const sortedArray = this.products
            .slice()
            .sort((a, b) => a['series'][0].value - b['series'][0].value);
          resultArray = sortedArray.slice(0, 3);
        }
      }

      if (
        this.selectedDashboardCategory.key === 'revenue_vs_cost' ||
        this.selectedDashboardCategory.key === 'vendor'
      ) {
        if (this.selectedDashboardCategory.key === 'revenue_vs_cost') {
          const sortedArray = this.products
            .slice()
            .sort((a, b) => a['profit_loss%'] - b['profit_loss%']);
          resultArray = sortedArray.slice(0, 3);
        }

        if (this.selectedDashboardCategory.key === 'vendor') {
          const sortedArray = this.products
            .slice()
            .sort((a, b) => a['achievement%'] - b['achievement%']);
          resultArray = sortedArray.slice(0, 3);
        }
      }
    } else if (this.reportOption === 'top') {
      if (
        this.selectedDashboardCategory.key !== 'revenue_vs_cost' ||
        this.selectedDashboardCategory.key !== 'vendor'
      ) {
        if (
          (this.selectedCategory.key === 'Location' &&
            !this.displayBackButton) ||
          (this.filteredCategory?.key === 'Location' && this.displayBackButton)
        ) {
          const sortedArray = this.products
            .slice()
            .sort((a, b) => b.value - a.value);
          resultArray = sortedArray.slice(0, 3);
        } else {
          const sortedArray = this.products
            .slice()
            .sort((a, b) => b['series'][0].value - a['series'][0].value);
          resultArray = sortedArray.slice(0, 3);
        }
      }

      if (this.selectedDashboardCategory.key === 'revenue_vs_cost') {
        const sortedArray = this.products
          .slice()
          .sort((a, b) => b['profit_loss%'] - a['profit_loss%']);
        resultArray = sortedArray.slice(0, 3);
      }
      if (this.selectedDashboardCategory.key === 'vendor') {
        const sortedArray = this.products
          .slice()
          .sort((a, b) => b['achievement%'] - a['achievement%']);
        resultArray = sortedArray.slice(0, 3);
      }
    } else {
      resultArray = this.products;
    }

    this.graphData = resultArray;
  }

  getFormattedAmount(value: any) {
    return String(value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ','));
  }

  setBarPadding(chart: any) {
    let length = setPadding(
      this.multi,
      this.selectedDashboardCategory,
      this.screenWidth,
      this.screenHeight
    );

    return length;
  }

  setTableName() {
    let res;

    if (
      this.selectedDashboardCategory.key === 'revenue_vs_cost' ||
      this.selectedDashboardCategory.key === 'vendor'
    ) {
      res =
        this.selectedDashboardCategory.key === 'vendor'
          ? ['recoengine_bls_revenue', 'recoengine_bls_projection']
          : ['recoengine_bls_revenue', 'recoengine_bls_cost'];
    } else {
      res = this.selectedDashboardCategory.key;
    }

    return res;
  }

  setGroupPadding(multi: any) {
    let width = 0;
    if (multi?.length === 1) {
      width = 1360;
    } else if (multi?.length === 2) {
      width = 150;
    } else if (multi?.length === 3) {
      width = 60;
    } else {
      width = 20;
    }

    return width;
  }

  displayValueName(value: any) {
    return value.name;
  }

  displayFilteredData(searchText: any) {
    let res = this.searchFilter.transform(this.products, searchText);

    if (res) {
      this.graphData = res;
    } else {
      this.graphData = this.products;
    }

    if (searchText === '') {
      this.graphData = this.products;
    }
  }

  getDate() {
    let fromDate;
    let toDate;
    if (this.rangeDates) {
      fromDate = this.datePipe.transform(this.rangeDates[0], 'MMMM-yyyy');
      toDate = this.datePipe.transform(this.rangeDates[1], 'MMMM-yyyy');
    }

    if (!this.rangeDates) {
      fromDate = 'September-2023';
      toDate = 'October-2023';

      this.rangeDates = [
        new Date('2023-08-31T18:30:00.000Z'),
        new Date('2023-09-30T18:30:00.000Z'),
      ];
    }
    this.selectedDate = {
      fromDate: fromDate ? fromDate : 'September-2023',
      toDate: toDate ? toDate : 'October-2023',
    };

    this.selectCategory(this.selectedCategory);
  }

  public convetToPDF() {
    let cashAccrualValue = '';
    if (
      this.selectedDashboardCategory.key === 'recoengine_bls_cost' ||
      this.selectedDashboardCategory.key === 'recoengine_bls_budgets'
    ) {
      cashAccrualValue = 'Amount';
    } else if (
      this.selectedDashboardCategory.key === 'recoengine_bls_projection' ||
      this.selectedDashboardCategory.key === 'recoengine_bls_revenue_target'
    ) {
      cashAccrualValue = 'Transaction_Value';
    } else {
      cashAccrualValue = this.value;
    }

    this.router.navigate(['/export-pdf'], {
      queryParams: {
        dashboard: JSON.stringify(this.selectedDashboardCategory),
        value: cashAccrualValue,
        date: this.rangeDates,
        cashAccural: this.value,
      },
    });
    // var data: any = document.getElementById('contentToConvert');
    // html2canvas(data).then((canvas) => {
    //   // Few necessary setting options
    //   var imgWidth = 208;
    //   var pageHeight = 295;
    //   var imgHeight = (canvas.height * imgWidth) / canvas.width;
    //   var heightLeft = imgHeight;

    //   const contentDataURL = canvas.toDataURL('image/png');
    //   let pdf = new jspdf('p', 'mm', 'a4'); // A4 size page of PDF
    //   var position = 0;
    //   pdf.addImage(contentDataURL, 'PNG', 0, position, imgWidth, imgHeight);
    //   pdf.save('new-file.pdf'); // Generated PDF
    // });
  }

  exportExcel() {
    let name =
      this.selectedDashboardCategory.key === 'revenue_vs_cost' ||
      this.selectedDashboardCategory.key === 'vendor'
        ? 'Net Profit'
        : 'Revenue' + '(' + this.value + ')';

    import('xlsx').then((xlsx) => {
      const customHeaders = ['Sr.No', this.xAxisLabel, name, 'Total'];
      const dataWithHeaders = [
        customHeaders,
        ...this.graphData.map((item) => [
          item.index,
          item.name,
          item.value ? item.value : item['net profit'],
          item.value ? item.value : item['net profit'],
        ]),
      ];

      const ws: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(dataWithHeaders);
      const wb: XLSX.WorkBook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

      XLSX.writeFile(wb, 'exported-data.xlsx');
    });
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any): void {
    this.getScreenSize();
  }

  getScreenSize(): void {
    this.screenWidth = window.innerWidth;

    this.screenHeight = window.innerHeight;
  }

  setProperty(event: any) {
    this.changedView = event.key;
    this.selectedCategory = event;

    this.changedDrillView = event.key;
  }

  setDrillValue(event: any) {
    this.selectedFilterCategory = event;
    this.changedDrillView = event.key;
  }
}
