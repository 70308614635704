import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';

const routes = {
  getDynamicNavigation: 'bls/revenue/dashboard/',
  getRevenueCostData: 'bls/dashboard/calculation/',
};


@Injectable({
  providedIn: 'root',
})

export class DashboardService {
  constructor(private http: HttpClient) {}

  //stacked bar chart dataset
    data =  [
      {
        name: 'Jan',
        series: [
          {
            name: '2010',
            value: 7300000,
          },
          {
            name: '2011',
            value: 8940000,
          },
        ],
      },
      {
        name: 'Feb',
        series: [
          {
            name: '2011',
            value: 7300000,
          },
          {
            name: '2012',
            value: 8940000,
          },
        ],
      },
      {
        name: 'Mar',
        series: [
          {
            name: '2012',
            value: 7300000,
          },
          {
            name: '2010',
            value: 8940000,
          },
        ],
      },
      {
        name: 'Apr',
        series: [
          {
            name: '2012',
            value: 7300000,
          },
          {
            name: '2010',
            value: 8940000,
          },
        ],
      },
      {
        name: 'May',
        series: [
          {
            name: '2012',
            value: 7300000,
          },
          {
            name: '2010',
            value: 8940000,
          },
        ],
      },
      {
        name: 'Jun',
        series: [
          {
            name: '2012',
            value: 7300000,
          },
          {
            name: '2010',
            value: 8940000,
          },
        ],
      },
    
      {
        name: 'Jul',
        series: [
          {
            name: '2010',
            value: 7870000,
          },
          {
            name: '2011',
            value: 5870000,
          },
          {
            name: '2012',
            value: 8270000,
          },
        ],
      },
    
      {
        name: 'Aug',
        series: [
          {
            name: '2010',
            value: 5000002,
          },
          {
            name: '2011',
            value: 5800000,
          },
        ],
      },
    
      {
        name: 'Sept',
        series: [
          {
            name: '2010',
            value: 5000002,
          },
          {
            name: '2011',
            value: 5800000,
          },
        ],
      },
    
      {
        name: 'Oct',
        series: [
          {
            name: '2010',
            value: 5000002,
          },
          {
            name: '2011',
            value: 5800000,
          },
        ],
      },
    
      {
        name: 'Nov',
        series: [
          {
            name: '2010',
            value: 5000002,
          },
          {
            name: '2011',
            value: 5800000,
          },
        ],
      },
    
      {
        name: 'Dec',
        series: [
          {
            name: '2010',
            value: 5000002,
          },
          {
            name: '2011',
            value: 5800000,
          },
        ],
      },
    ];

    // pie chart data set
    single = [
      {
        "name": "Germany",
        "value": 8940000
      },
      {
        "name": "USA",
        "value": 5000000
      },
      {
        "name": "France",
        "value": 7200000
      },
        {
        "name": "UK",
        "value": 6200000
      }
    ];
    
    // line chart data set
    lineChart = [
      {
        "name": "Germany",
        "series": [
          {
            "name": "1990",
            "value": 62000000
          },
          {
            "name": "2010",
            "value": 73000000
          },
          {
            "name": "2011",
            "value": 89400000
          }
        ]
      },
    
      {
        "name": "USA",
        "series": [
          {
            "name": "1990",
            "value": 250000000
          },
          {
            "name": "2010",
            "value": 309000000
          },
          {
            "name": "2011",
            "value": 311000000
          }
        ]
      },
    
      {
        "name": "France",
        "series": [
          {
            "name": "1990",
            "value": 58000000
          },
          {
            "name": "2010",
            "value": 50000020
          },
          {
            "name": "2011",
            "value": 58000000
          }
        ]
      },
      {
        "name": "UK",
        "series": [
          {
            "name": "1990",
            "value": 57000000
          },
          {
            "name": "2010",
            "value": 62000000
          }
        ]
      }
    ];
    
  
    getDashboardGraph(requestPayload: any): Observable<{ data: any[] }> {
      return this.http.post<{ data: any[] }>(`${routes.getDynamicNavigation}`, requestPayload);
    }

  
    getRevenueVsCostGraph(requestPayload: any): Observable<{ data: any[] }> {
      return this.http.post<{ data: any[] }>(`${routes.getRevenueCostData}`, requestPayload);
    }
}
