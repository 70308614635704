// comma-separated.pipe.ts

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'commaSeparated',
})
export class CommaSeparatedPipe implements PipeTransform {
  transform(value: number): string {
    // Check if the value is a valid number
    if (isNaN(value) || value === null) {
      return '';
    }

    // Convert the number to a string and add commas
    return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }
}
