import { Component} from '@angular/core';
import { jsPDF } from "jspdf";

@Component({
  selector: 'app-export-pdf',
  templateUrl: './export-pdf.component.html',
  styleUrls: ['./export-pdf.component.scss']
})
export class ExportPdfComponent {
  name = "Angular";

  generatePDF() {
    var doc = new jsPDF();
    doc.text("<p>Hello world!</p>", 10, 10);
    doc.save("Test.pdf");
  }
}
