<nav
  [ngClass]="hideHeader ? 'hidden' : 'flex justify-between items-center sticky top-0 px-8 py-4 border-b border-gray-100 bg-white z-40 shadow-md'"
>
  <div class="flex gap-6 items-center">
    <img src="assets/images/logo.png" alt="reconsyde" class="" style="-webkit-user-drag: none" />
  </div>

  <div class="hidden">

    <div class="flex items-center gap-6 text-gray-400">
      <!-- <fa-icon class="text-lg" [icon]="faCircleInfo"></fa-icon> -->
      <!-- <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M10 19.2499C15.108 19.2499 19.25 15.1089 19.25 9.99988C19.25 4.89188 15.108 0.749878 10 0.749878C4.891 0.749878 0.75 4.89188 0.75 9.99988C0.75 15.1089 4.891 19.2499 10 19.2499Z"
          stroke="#4E4E4E"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M9.99512 13.7958V9.37678"
          stroke="#4E4E4E"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M9.995 6.20386H10.005"
          stroke="#4E4E4E"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
  
      <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M18.1599 6.29988L12.4899 0.899878C11.7599 0.799878 10.9399 0.749878 10.0399 0.749878C3.7499 0.749878 1.6499 3.06988 1.6499 9.99988C1.6499 16.9399 3.7499 19.2499 10.0399 19.2499C16.3399 19.2499 18.4399 16.9399 18.4399 9.99988C18.4399 8.57988 18.3499 7.34988 18.1599 6.29988Z"
          stroke="#4E4E4E"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M11.9341 0.83252V3.49352C11.9341 5.35152 13.4401 6.85652 15.2981 6.85652H18.2491"
          stroke="#4E4E4E"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M9.66064 14.0172V7.9762"
          stroke="#4E4E4E"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M7.31494 11.6622L9.65994 14.0172L12.0049 11.6622"
          stroke="#4E4E4E"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
  
      <svg xmlns="http://www.w3.org/2000/svg" width="18" height="22" viewBox="0 0 18 22" fill="none">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M8.99638 1.51416C4.5621 1.51416 2.63543 5.5294 2.63543 8.18368C2.63543 10.1675 2.92305 9.58368 1.82496 12.0037C0.484003 15.4523 5.87638 16.8618 8.99638 16.8618C12.1154 16.8618 17.5078 15.4523 16.1678 12.0037C15.0697 9.58368 15.3573 10.1675 15.3573 8.18368C15.3573 5.5294 13.4297 1.51416 8.99638 1.51416Z"
          stroke="#4E4E4E"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M11.306 19.5122C10.0117 20.9579 7.9927 20.9751 6.68604 19.5122"
          stroke="#4E4E4E"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg> -->
      <div class="top-navbar-right ms-3">
        <ul class="navbar-nav flex text-center flex-row items-center justify-center">
          <li class="nav-item flex">
            <a class="nav-link px-4 relative" href="#">
              <div class="projects">
                <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M12.0001 21.8578C17.1081 21.8578 21.2501 17.7168 21.2501 12.6078C21.2501 7.49979 17.1081 3.35779 12.0001 3.35779C6.89112 3.35779 2.75012 7.49979 2.75012 12.6078C2.75012 17.7168 6.89112 21.8578 12.0001 21.8578Z" stroke="#4E4E4E" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M11.9952 16.4037V11.9847" stroke="#4E4E4E" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M11.995 8.81177H12.005" stroke="#4E4E4E" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                  </svg>
                  
              </div>
            </a>
          </li>
          <li class="nav-item">
            <a class="nav-link px-4 flex relative" href="#">
              <div class="projects">
                <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M20.1598 8.90779L14.4898 3.50779C13.7598 3.40779 12.9398 3.35779 12.0398 3.35779C5.74978 3.35779 3.64978 5.67779 3.64978 12.6078C3.64978 19.5478 5.74978 21.8578 12.0398 21.8578C18.3398 21.8578 20.4398 19.5478 20.4398 12.6078C20.4398 11.1878 20.3498 9.95779 20.1598 8.90779Z" stroke="#4E4E4E" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M13.9342 3.44043V6.10143C13.9342 7.95943 15.4402 9.46443 17.2982 9.46443H20.2492" stroke="#4E4E4E" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M11.6603 16.6251V10.5841" stroke="#4E4E4E" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M9.31458 14.2701L11.6596 16.6251L14.0046 14.2701" stroke="#4E4E4E" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                  </svg>
                  
              </div>
            </a>
          </li>
          <li class="nav-item">
            <a class="nav-link flex px-4 relative" href="#">
              <div class="notifications">
                <span class="notify-badge">8</span>
                <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M8.99614 1.12207C4.56185 1.12207 2.63519 5.13731 2.63519 7.79159C2.63519 9.7754 2.92281 9.19159 1.82471 11.6116C0.483759 15.0602 5.87614 16.4697 8.99614 16.4697C12.1152 16.4697 17.5076 15.0602 16.1676 11.6116C15.0695 9.19159 15.3571 9.7754 15.3571 7.79159C15.3571 5.13731 13.4295 1.12207 8.99614 1.12207Z" stroke="#4E4E4E" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                  </svg>
                  
              </div>
            </a>
          </li>
          <li class="nav-item relative">
            <a class="nav-link flex px-4 relative" href="javascript:void(0);" (click)="toggleMenu()">
              <div class="user-setting flex align-items-center gap-1">
                <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M11.8445 22.2698C8.15273 22.2698 5 21.6954 5 19.3946C5 17.0938 8.13273 14.9698 11.8445 14.9698C15.5364 14.9698 18.6891 17.0732 18.6891 19.374C18.6891 21.6738 15.5564 22.2698 11.8445 22.2698Z" fill="white"/>
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M11.8372 11.7816C14.26 11.7816 16.2236 9.81793 16.2236 7.39521C16.2236 4.97248 14.26 3.00793 11.8372 3.00793C9.41452 3.00793 7.44998 4.97248 7.44998 7.39521C7.4418 9.80975 9.3918 11.7734 11.8063 11.7816C11.8172 11.7816 11.8272 11.7816 11.8372 11.7816Z" fill="white"/>
                  </svg>
                  
                  <svg width="9" height="6" viewBox="0 0 9 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M1 0.864868C1 0.864868 3.392 4.35087 4.472 4.35087C5.552 4.35087 7.942 0.864868 7.942 0.864868" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                  </svg>                
              </div>
            </a>
            <ul class="dropdown-menu" *ngIf="isMenuOpened">
              <li class="menu-item">
                <span class="pi pi-user"></span>
                <h6>Jhon Deo</h6>
                <small>HR Manager</small>
              </li>
              <li class="menu-item">
                <a href="/">
                  <span class="pi pi-user-edit"></span>
                  Profile
                </a>
              </li>
              <li class="menu-item">
                <a href="/">
                  <span class="pi pi-briefcase"></span>
                  Dashboard
                </a>
              </li>
              <li class="menu-item">
                <a href="/">
                  <span class="pi pi-sign-out"></span>
                  Logout
                </a>
              </li>
            </ul>
          </li>
        </ul>
      </div>
    </div>
  </div>
</nav>

