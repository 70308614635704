import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DashboardComponent } from './dashboard.component';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { SelectButtonModule } from 'primeng/selectbutton';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ButtonModule } from 'primeng/button';
import { CalendarModule } from 'primeng/calendar';
import { DialogModule } from 'primeng/dialog';
import { RadioButtonModule } from 'primeng/radiobutton';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MenuModule } from 'primeng/menu';
import { DashboardService } from './dashboard.service';
import { HttpClientModule } from '@angular/common/http';
import { MessageService } from 'primeng/api';
import { TableModule } from 'primeng/table';
import { SearchFilterPipe } from './@shared/pipes/search-filter.pipe';
import { TabViewModule } from 'primeng/tabview';
import { CommaSeparatedPipe } from './@shared/pipes/comma-separated.pipe';
import { BadgeModule } from 'primeng/badge';
import { ExportPdfComponent } from './export-pdf/export-pdf.component';
import { CustomNumberFormatPipe } from './@shared/pipes/custom-number-format.pipe';
@NgModule({
  declarations: [DashboardComponent, SearchFilterPipe, CommaSeparatedPipe, ExportPdfComponent, CustomNumberFormatPipe],
  imports: [
    CommonModule,
    NgxChartsModule,
    SelectButtonModule,
    FormsModule,
    ReactiveFormsModule,
    ButtonModule,
    CalendarModule,
    DialogModule,
    RadioButtonModule,
    BrowserAnimationsModule,
    MenuModule,
    HttpClientModule,
    TableModule,
    TabViewModule,
    BadgeModule,
  ],
  providers:[DashboardService, MessageService]
})
export class DashboardModule {}
