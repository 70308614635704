<div
  class="container px-6 py-9 mx-auto"
  [ngClass]="visiblePopup ? 'blur-sm' : ''"
>
  <div class="head flex justify-between">
    <div class="left relative leftheading">
      <h2 class="text-3xl font-semibold tablet-view-head flex relative">
        <span class="web-view text-[#101828]">{{
          selectedDashboardCategory.name + " Dashboard"
        }}</span>
        <!-- <span class="tab-view text-[#101828]">{{
          selectedDashboardCategory.name
        }}</span> -->
        <span (click)="visibleDashboard = !visibleDashboard"
          ><i
            class="pi pi-angle-down mx-3 mt-1 cursor-pointer"
            style="font-size: 1.5rem"
            (click)="visible = false"
          ></i
        ></span>
      </h2>
      <!-- Dashboard Dropdown -->
      <div
        class="filter bg-white view-bydrop cursor-pointer z-[9999]"
        *ngIf="visibleDashboard"
        (mouseleave)="visibleDashboard = false"
      >
        <div class="flex flex-col gap-3 p-2">
          <div
            *ngFor="let category of dashboardCategories"
            class="field-checkbox flex justify-between items-center"
            (click)="onDashboardChange()"
          >
            <label [for]="category.key">{{ category.name }}</label>
            <p-radioButton
              [inputId]="category.key"
              name="category"
              [value]="category"
              [(ngModel)]="selectedDashboardCategory"
              styleClass="border border-2 rounded-2xl"
            ></p-radioButton>
          </div>
        </div>
      </div>
    </div>
    <div class="right flex gap-3">
      <div
        class="font-medium cursor-pointer px-2 py-2 flex justify-content-center items-center button relative rounded-lg bg-[#F9F5FF] text-[#6941C6]"
        (click)="visible = !visible"
      >
        <svg
          width="18"
          height="12"
          viewBox="0 0 18 12"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M4 6H14M1.5 1H16.5M6.5 11H11.5"
            stroke="#6941C6"
            stroke-width="1.66667"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
        <span class="px-2" (click)="visibleDashboard = false">View By </span>
        <!-- <p-button
          label="View By"
          styleClass="px-2 py-2 btnfocus text-sm w-max outline-none"          
        ></p-button> -->
        <!-- Filter Dropdown -->
        <div
          class="filter bg-white view-bydrop"
          *ngIf="visible"
          (mouseleave)="visible = false"
        >
          <div class="flex flex-col gap-3">
            <div
              *ngFor="let category of categories"
              class="field-checkbox flex justify-between items-center"
            >
              <label [for]="category.key" class="mr-2">{{
                category.name
              }}</label>
              <p-radioButton
                [inputId]="category.key"
                name="category"
                [value]="category"
                [(ngModel)]="selectedCategory"
                (click)="selectCategory(category)"
                (ngModelChange)="setProperty($event)"
                styleClass="border bg-pink rounded-2xl"
              ></p-radioButton>
            </div>
          </div>
        </div>
      </div>
      <!-- <ul>
        <li>

        </li>
      </ul> -->
      <div class="button rounded-lg">
        <p-calendar
          [(ngModel)]="rangeDates"
          selectionMode="range"
          (ngModelChange)="getDate()"
          [readonlyInput]="true"
          styleClass="border border-1 rounded-lg px-2 py-2"
          icon="pi pi-calendar"
          [showIcon]="true"
          view="month"
          dateFormat="mm/yy"
          (click)="visible = false; visibleDashboard = false"
        ></p-calendar>
      </div>

      <div class="button tab-box-cus">
        <div class="card flex justify-content-center">
          <p-selectButton
            [options]="stateOptions"
            [(ngModel)]="value"
            (ngModelChange)="onCashAccrualChange()"
            optionLabel="label"
            optionValue="value"
            styleClass="text-xxs w-max border border-1 rounded-xl"
            class="text-xxs"
            optionDisabled="constant"
          ></p-selectButton>
        </div>
      </div>
      <p-button
        label="Export"
        styleClass="p-2  border border-1 text-sm"
        icon="pi pi-cloud-download"
        class="web-view exp-btn-cus"
        (click)="convetToPDF()"
      ></p-button>


      <!-- <p-button
        styleClass="p-2  border border-1 text-sm"
        icon="pi pi-cloud-download"
        class="tab-view"
      ></p-button> -->
    </div>
  </div>
  <div
    class="charts mt-11 mx-0 p-2"
    (click)="closePopup()"
    [ngClass]="errMsg === '' ? 'border border-1 rounded-lg' : ''"
  >
    <div class="head" *ngIf="errMsg === ''">
      <div class="flex w-full justify-between">
        <div class="left left-dashboard">
          <p class="text-lg font-semibold text-[#101828]">
            {{ selectedCategory.name }}
            <span class="text-sm text-[#101828]">({{ value }})</span>
          </p>
          <!-- <p class="text-sm mt-1 text-[#475467]">
            Track how your rating compares to your industry average.
          </p> -->
        </div>
        <div class="right1"></div>
      </div>
      <div class="flex justify-center mt-2 bottom-margin-set">
        <p-button
          label="Back to original"
          styleClass="p-2 text-sm absolute left-[12px]"
          class="text-gray-500"
          icon="pi pi-chevron-left"
          (onClick)="resetGraph()"
          *ngIf="displayBackButton"
        ></p-button>
        <span
          *ngIf="selectedFilterCategory.name && displayBackButton"
          class="mt-2"
          ><b class="text-[14px]">
            {{ selectedFilterCategory.name }} for {{ feildData }}
          </b></span
        >
      </div>
    </div>

    <div class="err-check grid grid-cols-3" *ngIf="errMsg === ''">
      <div
        #containerRef
        style="width: 100%; gap: 10px"
        class="w-full mt-11 text-center flex mx-2 col-span-2"
        [ngClass]="
          enableChart.barChart
            ? displayBackButton
              ? 'pointer-events-none'
              : ''
            : 'hidden'
        "
      >
        <ngx-charts-bar-vertical
          [view]="[containerRef.offsetWidth, containerRef.offsetHeight]"
          [scheme]="colorScheme"
          [results]="multi"
          [xAxis]="showXAxis"
          [yAxis]="showYAxis"
          [legend]="showLegend"
          [showXAxisLabel]="showXAxisLabel"
          [showYAxisLabel]="showYAxisLabel"
          [xAxisLabel]="xAxisLabel"
          [yAxisLabel]="yAxisLabel"
          [animations]="animations"
          (select)="onSelect($event)"
          [barPadding]="setBarPadding(multi)"
          [roundDomains]="true"
          class="gap-4"
        >
        </ngx-charts-bar-vertical>
      </div>

      <div
        style="width: 100%; gap: 10px"
        class="w-full mt-11 text-center flex mx-2 col-span-2"
        [ngClass]="
          enableChart.groupedBarChart
            ? displayBackButton
              ? 'pointer-events-none'
              : ''
            : 'hidden'
        "
        #containerRef1
      >
        <ngx-charts-bar-vertical-2d
          [view]="[containerRef1.offsetWidth, containerRef1.offsetHeight]"
          [scheme]="colorScheme"
          [results]="multi"
          [gradient]="gradient"
          [xAxis]="showXAxis"
          [yAxis]="showYAxis"
          [legend]="showLegend"
          [showXAxisLabel]="showXAxisLabel"
          [showYAxisLabel]="showYAxisLabel"
          [xAxisLabel]="xAxisLabel"
          [yAxisLabel]="yAxisLabel"
          (select)="onSelect($event)"
          (activate)="onActivate($event)"
          (deactivate)="onDeactivate($event)"
          [roundDomains]="true"
          [legend]="true"
          [groupPadding]="setGroupPadding(multi)"
        >
        </ngx-charts-bar-vertical-2d>
      </div>

      <div
        class="flex col-span-2 location-piechart"
        [ngClass]="
          enableChart.pieChart
            ? selectedFilterCategory === 'Location'
              ? 'pointer-events-none'
              : ''
            : 'hidden'
        "
      >
        <!-- <ngx-charts-pie-chart
          [scheme]="colorScheme"
          [results]="single"
          [gradient]="gradient"
          (select)="onSelect($event)"
          (activate)="onActivate($event)"
          (deactivate)="onDeactivate($event)"
          [legend]="false"
          [arcWidth]="40"
          [doughnut]="true"
        >
        </ngx-charts-pie-chart> -->

        <ngx-charts-pie-chart
          [view]="[containerRef.offsetWidth, 450]"
          [scheme]="colorScheme"
          [results]="single"
          [gradient]="gradient"
          [legend]="showLegend"
          [legendPosition]="legendPosition"
          [labels]="true"
          [legend]="true"
          [doughnut]="true"
          (select)="onSelect($event)"
          (activate)="onActivate($event)"
          (deactivate)="onDeactivate($event)"
          [arcWidth]="0.5"
          [ngClass]="displayBackButton ? 'pointer-events-none' : ''"
        >
        </ngx-charts-pie-chart>
      </div>

      <div
        class="flex justify-center mt-5"
        [ngClass]="enableChart.lineChart ? '' : 'hidden'"
      >
        <ngx-charts-line-chart
          [view]="viewLineChart"
          [scheme]="colorScheme"
          [legend]="true"
          [showXAxisLabel]="true"
          [showYAxisLabel]="true"
          [xAxis]="true"
          [yAxis]="true"
          [xAxisLabel]="xAxisLabel"
          [yAxisLabel]="yAxisLabel"
          [timeline]="true"
          [results]="lineChart"
          (select)="onSelect($event)"
          (activate)="onActivate($event)"
          (deactivate)="onDeactivate($event)"
        >
        </ngx-charts-line-chart>
      </div>

      <div class="summary mt-12 p-10 pt-0">
        <div class="total">
          <p class="total-style">Total</p>
          <div class="total-value-inn flex relative">
            <p class="total-value">
              <span class="rsym">₹</span>
              {{ getFormattedAmount(getTotal(multi).toFixed(2)) }}
            </p>
            <!-- <p class="growth-ratio">
              <svg
                width="10"
                height="10"
                viewBox="0 0 10 10"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M5 8.5V1.5M5 1.5L1.5 5M5 1.5L8.5 5"
                  stroke="#12B76A"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
              24%
            </p> -->
          </div>
        </div>
        <p class="total-style mt-4 mb-2">
          {{
            this.selectedDashboardCategory.key === "revenue_vs_cost"
              ? "Profitability"
              : this.selectedDashboardCategory.key === "vendor"
              ? "Achievement"
              : ""
          }}
        </p>
        <p-tabView
          class="p-tabview-selected tbclus"
          [(activeIndex)]="activeIndex"
        >
          <p-tabPanel
            [header]="
              this.selectedDashboardCategory.key === 'revenue_vs_cost'
                ? 'Bottom 3 '
                : this.selectedDashboardCategory.key === 'vendor'
                ? 'Bottom 3 '
                : 'Bottom 3'
            "
            class="p-tabview-panel"
            (click)="activeIndex = 0"
          >
            <div class="top relative flex flex-col" *ngIf="activeIndex === 0">
              <p *ngFor="let value of getBottom3()" class="gap-3">
                <span class="param-name"> {{ displayValueName(value) }}</span>
                <span
                  class="param-name"
                  *ngIf="selectedFilterCategory === 'Location'"
                >
                  {{ displayValueName(value.name) }}</span
                >

                <!-- <span class="param-name" *ngIf="!value['series']">
                  <span class="indicator-map"></span>{{ value && value.value > 0 ? value.name : "" }}</span
                > -->
                <br />
                <span
                  class="total-value-data mt-2"
                  *ngIf="
                    this.selectedDashboardCategory.key === 'revenue_vs_cost' ||
                    this.selectedDashboardCategory.key === 'vendor'
                  "
                >
                  ₹
                  {{
                    getFormattedAmount(
                      value["net profit"] !== ""
                        ? value["net profit"].toFixed(2)
                        : 0
                    )
                  }}
                </span>
                <span
                  class="total-value-data mt-2"
                  *ngIf="
                    (this.selectedDashboardCategory.key !== 'revenue_vs_cost' &&
                      this.selectedDashboardCategory.key !== 'vendor') ||
                    selectedFilterCategory === 'Location'
                  "
                >
                  ₹
                  {{
                    getFormattedAmount(
                      value.value !== "" ? value.value?.toFixed(2) : 0
                    )
                  }}
                </span>

                <p-badge
                  *ngIf="
                    this.selectedDashboardCategory.key === 'revenue_vs_cost'
                  "
                  [value]="
                    value['profit%'] > 0
                      ? value['profit%'] + '%'
                      : value['loss%'] + '%'
                  "
                  [severity]="value['profit%'] > 0 ? 'success' : 'danger'"
                  class="mt-[0rem] absolute mx-5 badge-positi mr-0"
                >
                </p-badge>

                <span
                  *ngIf="selectedDashboardCategory.key === 'vendor'"
                  class="mt-3 absolute mx-5 badge-positi mr-0"
                  [ngClass]="
                  value['achievement%'] > 100 ? 'bg-green-100 rounded-lg px-3' : 'bg-red-100 rounded-lg px-3'
                "
                  [ngStyle]="{
                    color: value['achievement%'] > 100 ? 'green' : 'red'
                  }"
                >
                  {{ value["achievement%"] > 0 ? value["achievement%"] : 0 }}%
                </span>
              </p>
              <!-- <p class="growth-ratio">
                <svg
                  width="10"
                  height="10"
                  viewBox="0 0 10 10"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M5 8.5V1.5M5 1.5L1.5 5M5 1.5L8.5 5"
                    stroke="#12B76A"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
                9.2%
              </p> -->
            </div>
          </p-tabPanel>
          <p-tabPanel
            [header]="
              this.selectedDashboardCategory.key === 'revenue_vs_cost'
                ? 'Top 3'
                : this.selectedDashboardCategory.key === 'vendor'
                ? 'Top 3'
                : 'Top 3'
            "
            (click)="activeIndex = 1"
          >
            <div
              class="relative bottom flex flex-col"
              *ngIf="activeIndex === 1"
            >
              <p *ngFor="let value of getTop3Bottom3Value()" class="gap-3">
                <span class="param-name"> {{ displayValueName(value) }}</span>
                <span
                  class="param-name"
                  *ngIf="
                    (selectedCategory.key === 'Location' &&
                      !displayBackButton) ||
                    (filteredCategory?.key === 'Location' && displayBackButton)
                  "
                >
                  {{ displayValueName(value.name) }}</span
                >
                <br />
                <span
                  class="total-value-data mt-2"
                  *ngIf="
                    this.selectedDashboardCategory.key === 'revenue_vs_cost' ||
                    this.selectedDashboardCategory.key === 'vendor'
                  "
                >
                  ₹
                  {{
                    getFormattedAmount(
                      value["net profit"] !== ""
                        ? value["net profit"].toFixed(2)
                        : 0
                    )
                  }}

                  <p-badge
                    *ngIf="
                      this.selectedDashboardCategory.key === 'revenue_vs_cost'
                    "
                    [value]="
                      value['profit%'] > 0
                        ? value['profit%'] + '%'
                        : value['loss%'] + '%'
                    "
                    [severity]="value['profit%'] > 0 ? 'success' : 'danger'"
                    class="mt-[0rem] absolute mx-5 badge-positi mr-0 text-[16px]"
                  >
                  </p-badge>
                </span>
                <span
                  class="total-value-data mt-2"
                  *ngIf="
                    this.selectedDashboardCategory.key !== 'revenue_vs_cost' &&
                    this.selectedDashboardCategory.key !== 'vendor' &&
                    value.value > 0
                  "
                >
                  ₹
                  {{
                    getFormattedAmount(
                      value.value !== "" ? value.value.toFixed(2) : ""
                    )
                  }}
                </span>
                <span
                  *ngIf="selectedDashboardCategory.key === 'vendor'"
                  class="mt-3 absolute mx-5 badge-positi mr-0"
                  [ngClass]="
                  value['achievement%'] > 100 ? 'bg-green-100 rounded-lg px-3' : 'bg-red-100 rounded-lg px-3'
                "
                  [ngStyle]="{
                    color: value['achievement%'] > 100 ? 'green' : 'red'
                  }"
                >
                  {{ value["achievement%"] > 0 ? value["achievement%"] : 0 }}%
                </span>
              </p>
              <!-- <p class="growth-ratio">
                <svg
                  width="10"
                  height="10"
                  viewBox="0 0 10 10"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M5 8.5V1.5M5 1.5L1.5 5M5 1.5L8.5 5"
                    stroke="#12B76A"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
                9.2%
              </p> -->
            </div>
          </p-tabPanel>
        </p-tabView>
      </div>
    </div>
  </div>

  <div
    class="report mt-14 border border-1 rounded-lg mx-0"
    *ngIf="errMsg === ''"
  >
    <div class="flex justify-between">
      <div class="left-head">
        <p class="text-2xl font-semibold text-[#101828] mx-5 mt-5">Report</p>
      </div>
      <div class="right-button mt-5 mr-5">
        <div class="flex gap-3">
          <p-button
            label="Download"
            class="text-[#344054] exp-btn-cus"
            styleClass="p-2  border border-1 text-sm text-[#344054]"
            icon="pi pi-cloud-download"
            (click)="exportExcel()"
          ></p-button>

          <!-- <p-button
            label="View report"
            class="text-[#344054] exp-btn-cus"
            styleClass="p-2  border border-1 text-sm text-[#344054]"
          ></p-button> -->
        </div>
      </div>
    </div>
    <div class="line mt-5">
      <hr />
    </div>
    <div class="buttons flex justify-between mt-5 mx-5">
      <div class="left-button tab-box-cus">
        <div class="card flex justify-content-center">
          <p-selectButton
            [options]="reportStateOptions"
            [(ngModel)]="reportOption"
            optionLabel="label"
            optionValue="value"
            styleClass="text-xxs w-max border border-1 rounded-xl text-[#101828]"
            class="text-xxs"
            (ngModelChange)="filterTableData()"
          ></p-selectButton>
        </div>
      </div>
      <div class="right-button searchbox">
        <span class="p-input-icon-left border border-1 rounded-lg px-1 py-1">
          <i class="pi pi-search"></i>
          <input
            type="text"
            pInputText
            [(ngModel)]="searchText"
            class="outline-none mx-9"
            (ngModelChange)="displayFilteredData(searchText)"
            placeholder="Search"
          />
        </span>
      </div>
    </div>
    <div class="card mt-5 reptble">
      <p-table [value]="graphData" [tableStyle]="{ 'min-width': '50rem' }">
        <ng-template pTemplate="header" class="">
          <tr class="text-[#101828] text-xxs w-full font-normal">
            <th class="font-semibold f-12 mr-5 searchbox">Sr. No.</th>
            <th class="font-semibold f-12">{{ xAxisLabel }}</th>
            <th class="font-semibold f-12">
              {{
                selectedDashboardCategory.key === "revenue_vs_cost" ||
                this.selectedDashboardCategory.key === "vendor"
                  ? "Net Profit"
                  : "Revenue"
              }}
              ({{ value }})
            </th>
            <th class="font-semibold f-12">Total</th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-product>
          <tr class="border border-1">
            <td class="text-sm">{{ product.index }}</td>
            <td class="text-sm">{{ product.name }}</td>
            <td
              *ngIf="
                product &&
                product.series &&
                this.selectedDashboardCategory.key !== 'revenue_vs_cost' &&
                this.selectedDashboardCategory.key !== 'vendor'
              "
              class="text-sm"
            >
              {{ product?.series[0]?.value.toFixed(2) }}
            </td>
            <td
              *ngIf="
                product &&
                product.series &&
                (this.selectedDashboardCategory.key === 'revenue_vs_cost' ||
                  this.selectedDashboardCategory.key === 'vendor')
              "
              class="text-sm"
            >
              {{ product["net profit"].toFixed(2) }}
            </td>
            <td class="text-sm">
              {{
                product["net profit"]
                  ? product["net profit"].toFixed(2)
                  : product?.value.toFixed(2)
              }}
            </td>
            <td
              class="text-sm"
              *ngIf="
                (selectedCategory.key === 'Location' && !displayBackButton) ||
                (filteredCategory?.key === 'Location' && displayBackButton)
              "
            >
              {{
                product["net profit"]
                  ? product["net profit"].toFixed(2)
                  : product?.value.toFixed(2)
              }}
            </td>
          </tr>
        </ng-template>
      </p-table>
    </div>
  </div>

  <div
    class="temp-err-msg flex justify-center border border-1 rounded-2xl h-[100vh]"
    *ngIf="errMsg !== ''"
  >
    <p class="font-semibold text-xl mt-[14rem]">{{ errMsg }}</p>
  </div>
</div>

<p-dialog
  header="Drill Down By"
  [(visible)]="visiblePopup"
  [modal]="true"
  [style]="{ width: '32vw' }"
  [draggable]="false"
  [resizable]="false"
>
  <div class="flex flex-col gap-3 p-2">
    <div
      *ngFor="let category of filterCategory()"
      class="field-checkbox flex justify-between items-center view-bydrop"
      (click)="selectCategoryFilter(category)"
    >
      <label [for]="category.key">{{ category.name }}</label>
      <p-radioButton
        [inputId]="category.key"
        name="category"
        [value]="category"
        [(ngModel)]="selectedFilterCategory"
        (ngModelChange)="setDrillValue($event)"
        styleClass="border border-2 rounded-2xl"
      ></p-radioButton>
    </div>
  </div>
</p-dialog>
